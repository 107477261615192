import React, { useState, useRef, useEffect  } from "react";
import { motion, useViewportScroll, useTransform } from "framer-motion";
import { useMediaQuery } from 'react-responsive';
import Footer from "../components/Footer";
import Header from "../components/Header";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";



const responsivepatner = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 3
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};


const banner = {

    animate: {
      transition: {
        delayChildren: 0,
        staggerChildren: 0.1,
      },
    },
  };

 
  const letterAni = {
    initial: { y: 400 },
    animate: {
      y: 0,
      transition: {
        ease: [0.6, 0.01, -0.05, 0.95],
        duration: 1,
      },
    },
  };



  const Images1 = ({ speed }) => {
    const { scrollYProgress } = useViewportScroll();
    const yValue = useTransform(scrollYProgress, [0, 1], [0, 200*speed]);
  
        return(
          <motion.div initial={{opacity:0}} animate={{opacity:1}} transition={{duration:1}} style={{y:yValue}}>
             <img className="img-100" src={process.env.PUBLIC_URL + `/images/viluxur_home_page_we-are-viluxur.jpg`} />
          </motion.div>
        )
  };
  

  
  const Images3 = ({ speed }) => {
    const { scrollYProgress } = useViewportScroll();
    const yValue = useTransform(scrollYProgress, [0, 1], [0, 200*speed]);
  
        return(
          <motion.div initial={{opacity:0}} animate={{opacity:1}} transition={{duration:1}} style={{y:yValue}}>
             <img className="img-100" src={process.env.PUBLIC_URL + `/images/viluxur_home_page.jpg`} />
          </motion.div>
        )
  };



  const Ellice = ({ speed }) => {
    const { scrollYProgress } = useViewportScroll();
    const yValue = useTransform(scrollYProgress, [0, 1], [0, 200*speed]);
  
        return(
          <motion.div initial={{opacity:0}} animate={{opacity:1}} transition={{duration:1}} style={{y:yValue}}>
             <img className="img-100" src={process.env.PUBLIC_URL + `/images/Our.jpg`} />
          </motion.div>
        )
  };
  

  const Ellice2 = ({ speed }) => {
    const { scrollYProgress } = useViewportScroll();
    const yValue = useTransform(scrollYProgress, [0, 1], [0, 200*speed]);
  
        return(
          <motion.div initial={{opacity:0}} animate={{opacity:1}} transition={{duration:1}} style={{y:yValue}}>
             <img className="img-100" src={process.env.PUBLIC_URL + `/images/xx.jpg`} />
          </motion.div>
        )
  };

function Crypto() {

    const [countryNameMap, setCountryNameMap] = useState([]);

    const [BoxTypeOfHolidayValue, setBoxTypeOfHolidayValue] = useState([]);
    const [typeOfHoliday, setTypeOfHoliday] = useState(true);
  
    const [CountryPname, sethandleCountryPname] = useState(0);
    const [adults, setadults] = useState(0);
    const [children,setchildren] = React.useState('');  
  
    const handleCountryPname = (event) => {
      sethandleCountryPname(event.target.value);
    }
  
  
  
    const handleChangeadults = (event) => {
  
      console.log(event);
      setadults(event.target.value);
    };
    
  
    const handleChangechildren = (event) => {
      setchildren(event.target.value);
    };
  
  
  
    const checkBoxTypeOfHoliday = (event) => {
      let newArray3 = [...BoxTypeOfHolidayValue, event.target.value, ];
      if (BoxTypeOfHolidayValue.includes(event.target.value)) {
      newArray3 = newArray3.filter(day2 => day2 !== event.target.value);
    }
  
    setBoxTypeOfHolidayValue(newArray3)
  
    if (newArray3.length > 0) {
      setTypeOfHoliday(false);
    } else {
      setTypeOfHoliday(true);
    }
  
    }
  
  
    useEffect(()=>{
  
      fetch('https://www.ovholidays.com/experience/get-country-all.php', {
        method: 'GET',
        headers: {
            "Content-Type": "application/json"
        }
         }).then((response) => response.json())
        .then(data => 
          
          setCountryNameMap(data)
          // console.log(data)
          )
        .catch((error) => {
            console.log('not work');
            console.log(error);
        });
  
  
  
    },[])
  
  
  
    // validation start
   const [fname, setFName] = useState('');
   const [lname, setLName] = useState('');
   const [email, setEmail] = useState('');
   const [phone, setPhone] = useState('');
   const [whatsapp, setWhatsapp] = useState('');
  
   const [FNameArray, setFNameArray] = useState([]);
   const [LNameArray, setLNameArray] = useState([]);
   const [emailArray, setEmailArray] = useState([]);
   const [phoneArray, setPhoneArray] = useState([]);
   const [whatsappArray, setWhatsappArray] = useState([]);
  
  
  
   const FnameChange = (event) => {
     setFName(event.target.value);
   }
   const LnameChange = (event) => {
    setLName(event.target.value);
  }
  
   const emailChange = (event) => {
     setEmail(event.target.value);
   }
  
   const phoneChange = (event) => {
    setPhone(event.target.value);
  }
  
  const whatsappChange = (event) => {
    setWhatsapp(event.target.value);
  }
  
  const [isActive, setActive] = useState(false);
  
  const formSubmitHandler = (event) => {
  
    setActive(true)
    event.preventDefault();
    
    let return_status = true;
    let newList = [];
    let newListbudgect = [];
    let FNamevalue = [];
    let LNamevalue = [];
    let Emailvalue = [];
    let Phonevalue = [];
    let Whatsappvalue = [];
    let Massagevalue = [];
  
    if(fname == ""){
      FNamevalue.push(`Full Name Required`);
      return_status = false;
      setActive(false)
    }
  
    if(lname == ""){
      LNamevalue.push(`Travel Agency / Tour Operator Name Name Required`);
      return_status = false;
      setActive(false)
    }
  
  
    if(email == ""){
      Emailvalue.push(`Valid Email Address Required`);
      return_status = false;
      setActive(false)
    } else {
      var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
  
      if (!pattern.test(email)) {
          Emailvalue.push(`Please enter valid email Address`);
          return_status = false;
          setActive(false)
        }
    }
   
    if(phone == ""){
      Phonevalue.push(`Company Number required`);
      return_status = false;
      setActive(false)
    }
  
    if(whatsapp  == ""){
      Whatsappvalue.push(`Whatsapp Number required`);
      return_status = false;
      setActive(false)
    }
   
   
  
    setFNameArray(FNamevalue);
    setLNameArray(LNamevalue);
    setEmailArray(Emailvalue);
    setPhoneArray(Phonevalue);
    setWhatsappArray(Whatsappvalue);
   
  
    
    if (return_status) {
      fetch('https://www.viluxurholidays.com/thank-you-vh.php', {
        method: 'POST',
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
        
          fldCustomerFormMessage1 : "Viluxur Form",
        
          fldCustomerFName : fname,
          fldTravelAgancyName : lname,
          fldCustomerEMail : email,
          fldCustomerPhone : phone,
          fldCustomerWhatsapp : whatsapp,
          fldCountryName:CountryPname,
          fldAdults:adults,
          fldChildren:children,
          fldtypeOfHoliday:typeOfHoliday
  
  
        
  
         
        })
    }).then((response) => response.json())
        .then( data =>{
            
            if (data.code == 200) {
              window.location.href = 'https://www.ovholidays.com/thankyou.php';
            } else {
              window.location.href = 'https://www.ovholidays.com/errorovx.php';
            }
          }).catch((error) => {
            console.log('not work');
            console.log(error);
        });
    }  
  }
  // validation end
  
 


  const [playMarquee, setPlayMarquee] = useState(false);

  useEffect(() => {
    setPlayMarquee(true);
    
  }, []);
 
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 1224px)'
  })
  const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
  const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })



  return (
    <div>
        <Header/>
        {isDesktopOrLaptop && 

        <div className="ve-are-viluxur-top-page">
         {/* <motion.div className="banner  m-xy-s viluxur-m-top-header banner-inner" variants={banner}>
        <BannerRowTop />

        </motion.div> */}
       
        <div className="transition-image mt-final final we-are-viluxr-img">
        <motion.img src={process.env.PUBLIC_URL + `/images/viluxur_crypto.jpg`} />
        </div>

        <motion.div
        initial={{ opacity: 0, y: 100 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{
        ease: "easeInOut",
        duration: 1,
        delay: 0.6,}}
        className=""
          >

    <motion.div className="Middle">
     <div className="we-got-you width-800 we-are-viluxur">
      <div>
        <h1 className="title-2 color-white ">VILUXUR ACCEPTS <br/>CRYPTO PAYMENTS </h1>
        <p className="para color-white width-para-s">Your clients can now purchase their luxury holidays at the world’s most desired Maldives properties with cryptocurrency. </p>
       
      </div>
     
     </div>
     
    </motion.div>

    </motion.div>

  <section className="container mt-12">
            <div className="row mb-5">
            <div className="col-12">
            <h2 className="title-2 text-center">Payments in the following cryptocurrencies will be accepted.</h2>
            </div>
    </div>
    <div className="row ">
            <div className="col-2 text-center">
            <motion.img src={process.env.PUBLIC_URL + `/images/7.png`} width="100px"/>
            <p className="mt-4">Bitcoin</p>
            </div>

            <div className="col-2 text-center">
            <motion.img src={process.env.PUBLIC_URL + `/images/1.png`} width="100px"/>
            <p className="mt-4">USDT</p>
            </div>

            <div className="col-2 text-center">
            <motion.img src={process.env.PUBLIC_URL + `/images/3.png`} width="100px"/>
            <p className="mt-4">Stellar</p>
            </div>

            <div className="col-2 text-center">
            <motion.img src={process.env.PUBLIC_URL + `/images/4.png`} width="100px"/>
            <p className="mt-4">Solana</p>
            </div>

            <div className="col-2 text-center">
            <motion.img src={process.env.PUBLIC_URL + `/images/5.png`} width="100px"/>
            <p className="mt-4">Litecoin</p>
            </div>

            <div className="col-2 text-center">
            <motion.img src={process.env.PUBLIC_URL + `/images/6.png`} width="100px"/>
            <p className="mt-4">Etherum</p>
            </div>
    </div>

  </section>

  <section className="container mt-8">
  <div className="row mb-5">
            <div className="col-12">
            <h2 className="title-2 text-center">Crypto Asset Buyer Process</h2>
            </div>
    </div>  
    <div className="row mt-5">
    <div className="">
                    <p className="para">
                    <ul className="our_servis border_btm">
                        <li>1. Find your customer's dream resort on Viluxur Holidays </li>
                        <li>2. Fill out the inquiry form below with the resort and particulars of your client's holiday</li>
                        <li>3. Viluxur Holidays will coordinate an exclusive island getaway  fit to your client's requests and send you a quotation </li>
                        <li>4. Once you confirm the package - Viluxur Holidays will send you a link with the amount through our licensed, regulatory approved payment gateway</li>
                    </ul>
                    </p>
                    
                </div>
        </div> 
  </section>



    <section className="container-fluid mt-8">
          <div className="row">
            <div className="col-12">
            <motion.img className="width-100" src={process.env.PUBLIC_URL + `/images/viluxur_crypto_banner.jpg`} />
            </div>
          </div>
    </section>


    <section className="container mt-8">
      <div>
      <div className="row padding-r-l-30">
          <div className="col-12 ">
          <div className=" ">
            <div>
              <h2 className="title-2 text-center"> Simply start searching, find the luxury property your client wants to holiday at and we will take care of the rest.</h2>
             
            </div>
          </div>
          </div>
         
        </div>
      </div>
    </section>

    <section className="container ">
          <div className="row">
              <div className="col-12 ">
              <form className="mt-5-form">
          <div className="row input-style">
            <div className="col-6">
            <p className="para-form"> Full Name:</p>
            <input type="text"  autoComplete="off" name="fldCustomerFName" onChange={FnameChange} />
            <div className="error-waper">
            <div className="erroe-msg">{FNameArray[0]}</div>
          </div>
          </div>
            <div className="col-6">
            <p className="para-form"> Travel Agency / Tour Operator Name</p>
            <input type="text"  autoComplete="off" name="fldCustomerLName" onChange={LnameChange} />
            <div className="error-waper">
            <div className="erroe-msg">{LNameArray[0]}</div>
          </div>
          </div>
          </div>

            
          <div className="row input-style">
            <div className="col-6">
            <p className="para-form"> Country Of Origin </p>
            <select className="width-100 outline-none"
              labelId="country_id"
              id="country_id"
              defaultValue={""}
              onChange={handleCountryPname}
              name="fldCustomerFormResort1ID"
            >

              {countryNameMap.map(item=> (
                <option value={item.fldCountryName}>{item.fldCountryName}</option>
              ))}
            </select>
            </div>
            <div className="col-6">
            <p className="para-form"> Email:</p>
            <input type="text"  name="fldCustomerEmail" onChange={emailChange}/>
            <div className="error-waper">
            <div className="erroe-msg">{emailArray[0]}</div>
            </div>
            </div>
          </div>

            <div className="row input-style">
            <div className="col-6">
            <p className="para-form"> Company Number </p>
            <input type="text" name="fldCustomerPNumber" onChange={phoneChange}/>
            <div className="error-waper">
            <div className="erroe-msg">{phoneArray[0]}</div>
            </div>
            </div>
            <div className="col-6">
            <p className="para-form"> Whatsapp Number </p>
            <input type="text" name="fldCustomerPNumber" onChange={whatsappChange}/>
            <div className="error-waper">
            <div className="erroe-msg">{whatsappArray[0]}</div>
          </div>
            </div>
            
          </div>

          <div className="row input-style">
            <div className="row">
                  <div className="col-6">
                  <p className="para-form mb-20">Number Of Pax</p>
                  </div>
                  <div className="col-6">
                  <p className="para-form mb-20">Mice Or Leisure Travel?</p>
                  </div>
            </div>
            <div className="col-6 plr-3 flex-align-center">
                           <select 
                            className="nightSelect mr-right-form outline-none "
                              labelId="adults"
                              id="adults"
                              value={adults}
                              name="fldCustomerFormadults"
                              onChange={handleChangeadults}
                            >
                                <option  value={"2 Adults"}>1 Adults</option>
                                <option  value={"2 Adults"}>2 Adults</option>
                                <option value={"3 Adults"}>3 Adults</option> 
                                <option value={"4 Adults"}>4 Adults</option>
                                <option value={"5 Adults"}>5 Adults</option>
                                <option value={"6 Adults"}>6 Adults</option>
                                <option value={"7 Adults"}>7 Adults</option>
                                <option value={"8 Adults"}>8 Adults</option> 
                                <option value={"9 Adults"}>9 Adults</option>
                                <option value={"10 Adults"}>10 Adults</option>
                            </select>

                           <select 
                            className="nightSelect outline-none"
                              labelId="adults"
                              id="adults"
                              value={children}
                              name="fldCustomerFormadults"
                              onChange={handleChangechildren}
                            >
                                <option value={"0 children"}>0 Children</option>
                                <option value={"1 children"}>1 Child</option>
                                <option value={"2 children"}>2 Children</option> 
                                <option value={"3 children"}>3 Children</option>
                                <option value={"4 children"}>4 Children</option>
                            </select>
            </div>
            <div className="col-6">
            
            <FormGroup className="display-inline-row">
            <FormControlLabel className="form-checkpoint" onChange={checkBoxTypeOfHoliday} control={<Checkbox  name="fldCustomerFormTypeOFMice" value="Mice" />} label="Mice"/>
            <FormControlLabel className="form-checkpoint" onChange={checkBoxTypeOfHoliday} control={<Checkbox name="fldCustomerFormTypeOFLeisureTravel" value="Leisure Travel"  /> } label="Leisure Travel"/>
            </FormGroup>
            </div>
          
          </div>
          <div className="row input-style mt-50">
          <button className="submit-btn" type="submit" value="Submit" onClick={formSubmitHandler} >Submit</button>
          </div>
          
        </form>
              </div>
          </div>
</section>


</div>


        }
       
       {isTabletOrMobile && 
       
       <div className="page-space-top-mobile we-are-viluxur-page">
         <motion.div
          initial={{ opacity: 0, y: 180 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{
          ease: "easeInOut",
          duration: 1,
          delay: 0.6,}}
          className=""
        >
     

          <div className="transition-image mt-final final">
        <motion.img src={process.env.PUBLIC_URL + `/images/viluxur_crypto.jpg`} />
        </div>

        <div className="we-got-you">
      <div>
      <span className="quote-icon-gray">
      <svg width="24px" height="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><rect x="0" fill="none" width="24" height="24"/><g><path d="M11.192 15.757c0-.88-.23-1.618-.69-2.217-.326-.412-.768-.683-1.327-.812-.55-.128-1.07-.137-1.54-.028-.16-.95.1-1.956.76-3.022.66-1.065 1.515-1.867 2.558-2.403L9.373 5c-.8.396-1.56.898-2.26 1.505-.71.607-1.34 1.305-1.9 2.094s-.98 1.68-1.25 2.69-.346 2.04-.217 3.1c.168 1.4.62 2.52 1.356 3.35.735.84 1.652 1.26 2.748 1.26.965 0 1.766-.29 2.4-.878.628-.576.94-1.365.94-2.368l.002.003zm9.124 0c0-.88-.23-1.618-.69-2.217-.326-.42-.77-.692-1.327-.817-.56-.124-1.074-.13-1.54-.022-.16-.94.09-1.95.75-3.02.66-1.06 1.514-1.86 2.557-2.4L18.49 5c-.8.396-1.555.898-2.26 1.505-.708.607-1.34 1.305-1.894 2.094-.556.79-.97 1.68-1.24 2.69-.273 1-.345 2.04-.217 3.1.165 1.4.615 2.52 1.35 3.35.732.833 1.646 1.25 2.742 1.25.967 0 1.768-.29 2.402-.876.627-.576.942-1.365.942-2.368v.01z"/></g></svg></span>
      <h1 className="title-2 ">VILUXUR ACCEPTS CRYPTO PAYMENTS</h1>
        <p className="para ">Your clients can now purchase their luxury holidays at the world’s most desired Maldives properties with cryptocurrency.</p>
      </div>
     </div>

     


        <section className="container-fluid ">

  <div className="row ">

    <div className="col-12">
    <div className="pdig-ex-30">
    <h2 className="title-2 text-center mb-5">Payments in the following cryptocurrencies will be accepted. </h2>
            <div className=" mt-5"> 
            <div className="row  mb-4">
            <div className="col-6 text-center">
            <motion.img src={process.env.PUBLIC_URL + `/images/7.png`} width="60px"/>
            <p className="mt-4">Bitcoin</p>
            </div>

            <div className="col-6 text-center">
            <motion.img src={process.env.PUBLIC_URL + `/images/1.png`} width="60px"/>
            <p className="mt-4">USDT</p>
            </div>

          

          
    </div>
    <div className="row mb-4">
  <div className="col-6 text-center">
            <motion.img src={process.env.PUBLIC_URL + `/images/3.png`} width="60px"/>
            <p className="mt-4">Stellar</p>
            </div>

            <div className="col-6 text-center">
            <motion.img src={process.env.PUBLIC_URL + `/images/4.png`} width="60px"/>
            <p className="mt-4">Solana</p>
            </div>
  </div>
  <div className="row mb-4">
  <div className="col-6 text-center">
            <motion.img src={process.env.PUBLIC_URL + `/images/5.png`} width="60px"/>
            <p className="mt-4">Litecoin</p>
            </div>

            <div className="col-6 text-center">
            <motion.img src={process.env.PUBLIC_URL + `/images/6.png`} width="60px"/>
            <p className="mt-4">Etherum</p>
            </div>
  </div>
            </div>
          </div>
    </div>

  </div>
  
 
</section>



<section className="container mt-8">
  <div className="row mb-5">
            <div className="col-12">
            <h2 className="title-2 text-center">Crypto Asset Buyer Process</h2>
            </div>
    </div>  
    <div className="row mt-5">
    <div className="">
                    <p className="para">
                    <ul className="our_servis border_btm">
                        <li>1. Find your customer's dream resort on Viluxur Holidays </li>
                        <li>2. Fill out the inquiry form below with the resort and particulars of your client's holiday</li>
                        <li>3. Viluxur Holidays will coordinate an exclusive island getaway  fit to your client's requests and send you a quotation </li>
                        <li>4. Once you confirm the package - Viluxur Holidays will send you a link with the amount through our licensed, regulatory approved payment gateway</li>
                    </ul>
                    </p>
                    
                </div>
        </div> 
  </section>

  <section className="container-fluid mt-8">
          <div className="row">
            <div className="col-12">
            <motion.img className="width-100" src={process.env.PUBLIC_URL + `/images/viluxur_crypto_banner.jpg`} />
            </div>
          </div>
    </section>

  <section className="container  mb-3">
  
          <div className="row">
              <div className="col-12 bg-white pdn-30-form">
              <h2 className="title-2 text-center mb-5">Simply start searching, find the luxury property your client wants to holiday at and we will take care of the rest.</h2>
          <form className="mt-5-form ">
          <div className="row input-style">
            <div className="col-12">
            <p className="para-form"> Full Name:</p>
            <input type="text"  autoComplete="off" name="fldCustomerFName" onChange={FnameChange} />
            <div className="error-waper">
            <div className="erroe-msg">{FNameArray[0]}</div>
          </div>
          </div>
            <div className="col-12">
            <p className="para-form"> Travel Agency / Tour Operator Name</p>
            <input type="text"  autoComplete="off" name="fldCustomerLName" onChange={LnameChange} />
            <div className="error-waper">
            <div className="erroe-msg">{LNameArray[0]}</div>
          </div>
          </div>
          </div>

            
          <div className="row input-style">
            <div className="col-12">
            <p className="para-form"> Country Of Origin </p>
            <select className="width-100 outline-none"
              labelId="country_id"
              id="country_id"
              defaultValue={""}
              onChange={handleCountryPname}
              name="fldCustomerFormResort1ID"
            >

              {countryNameMap.map(item=> (
                <option value={item.fldCountryName}>{item.fldCountryName}</option>
              ))}
            </select>
            </div>
            <div className="col-12">
            <p className="para-form"> Email:</p>
            <input type="text"  name="fldCustomerEmail" onChange={emailChange}/>
            <div className="error-waper">
            <div className="erroe-msg">{emailArray[0]}</div>
            </div>
            </div>
          </div>

            <div className="row input-style">
            <div className="col-12">
            <p className="para-form"> Company Number </p>
            <input type="text" name="fldCustomerPNumber" onChange={phoneChange}/>
            <div className="error-waper">
            <div className="erroe-msg">{phoneArray[0]}</div>
            </div>
            </div>
            <div className="col-12">
            <p className="para-form"> Whatsapp Number </p>
            <input type="text" name="fldCustomerPNumber" onChange={whatsappChange}/>
            <div className="error-waper">
            <div className="erroe-msg">{whatsappArray[0]}</div>
          </div>
            </div>
            
          </div>

          <div className="row input-style">
          
            <div className="col-12 plr-3">
            <p className="para-form mb-20">Number Of Pax</p>
            <div className="flex-align-center">
            <select 
                            className="nightSelect mr-right-form outline-none "
                              labelId="adults"
                              id="adults"
                              value={adults}
                              name="fldCustomerFormadults"
                              onChange={handleChangeadults}
                            >
                                <option  value={"2 Adults"}>1 Adults</option>
                                <option  value={"2 Adults"}>2 Adults</option>
                                <option value={"3 Adults"}>3 Adults</option> 
                                <option value={"4 Adults"}>4 Adults</option>
                                <option value={"5 Adults"}>5 Adults</option>
                                <option value={"6 Adults"}>6 Adults</option>
                                <option value={"7 Adults"}>7 Adults</option>
                                <option value={"8 Adults"}>8 Adults</option> 
                                <option value={"9 Adults"}>9 Adults</option>
                                <option value={"10 Adults"}>10 Adults</option>
                            </select>

                           <select 
                            className="nightSelect outline-none"
                              labelId="adults"
                              id="adults"
                              value={children}
                              name="fldCustomerFormadults"
                              onChange={handleChangechildren}
                            >
                                <option value={"0 children"}>0 Children</option>
                                <option value={"1 children"}>1 Child</option>
                                <option value={"2 children"}>2 Children</option> 
                                <option value={"3 children"}>3 Children</option>
                                <option value={"4 children"}>4 Children</option>
                            </select>
            </div>
                           
            </div>
            <div className="col-12">
            <p className="para-form mb-20">Mice Or Leisure Travel?</p>
              <div>
              <FormGroup className="display-inline-row">
               <FormControlLabel className="form-checkpoint" onChange={checkBoxTypeOfHoliday} control={<Checkbox  name="fldCustomerFormTypeOFMice" value="Mice" />} label="Mice"/>
                <FormControlLabel className="form-checkpoint" onChange={checkBoxTypeOfHoliday} control={<Checkbox name="fldCustomerFormTypeOFLeisureTravel" value="Leisure Travel"  /> } label="Leisure Travel"/>
            </FormGroup>
              </div>
            
         
            </div>
          
          </div>
          <div className="row input-style mt-50">
          <button className="submit-btn" type="submit" value="Submit" onClick={formSubmitHandler} >Submit</button>
          </div>
          
        </form>  
              </div>
          </div>
</section>



</motion.div>
       </div>
       
       
       }

        <Footer/>
    </div>
  );
}

const BannerRowTop = ({ title }) => {
    return (
      <div className={"banner-row"}>
        <div className="">
          <AnimatedLetters className="margin-left" title={"We're"} />
          <AnimatedLetters3 className="ml-4"  title={'Viluxur'} />
        </div>
    
      
      </div>
    );
  };
  
  const BannerRowBottom = ({ title }) => {
    return (
      <div className={"banner-row center ml-5"}>
              <motion.div
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{ ease: [0.6, 0.01, -0.05, 0.95], duration: 1, delay: 1 }} >
        </motion.div>
        <AnimatedLetters title={title} />
      </div>
    );
  };
  
  const BannerRowCenter = ({ title, playMarquee }) => {
    return (
      <div className={`banner-row marquee  ${playMarquee && "animate"}`}>
        <div className="marquee__inner">
        <AnimatedLetters title={'Serenity'}  />
        <AnimatedLetters title={'Sun'}  />
        <AnimatedLetters title={'Sand'}  />
        <AnimatedLetters title={'Sophistication'}  />
        <AnimatedLetters title={'Serendipity'}  />
        </div>
      </div>
    );
  };

  const ImagesRow = ({ title, playMarquee }) => {
    return (
      <div className={`banner-row marquee  ${playMarquee && "animate"}`}>
        <div className="marquee__inner padding-redus logo-row-images">
        <Imagess1  />
        <Imagess2  />
        <Imagess1  />
        <Imagess3  />
        <Imagess1  />
        <Imagess4  />
        <Imagess1  />
        <Imagess5  />
        <Imagess1  />
        <Imagess6  />
        <Imagess1  />
        <Imagess7  />
        <Imagess1  />
        <Imagess8  />
        <Imagess1  />
        <Imagess9  />
        <Imagess1  />
        <Imagess2  />
        <Imagess1  />
        <Imagess3  />
        <Imagess1  />
        <Imagess4  />
        </div>
      </div>
    );
  };


  const Imagess1 = ({ title, disabled }) => (
    <motion.span 
    className="row-title"
    variants={disabled ? null : banner}
    initial="initial"
    animate="animate">
        <div>
          <img src={process.env.PUBLIC_URL + `/images/arrow.png`}/>
        </div>
    </motion.span>
  );

  
  const Imagess2 = ({ title, disabled }) => (
    <motion.span 
    className="row-title"
    variants={disabled ? null : banner}
    initial="initial"
    animate="animate">
        <div>
          <img src={process.env.PUBLIC_URL + `/images/logos/logo_1.png`}/>
        </div>
    </motion.span>
  );

  
  const Imagess3 = ({ title, disabled }) => (
    <motion.span 
    className="row-title"
    variants={disabled ? null : banner}
    initial="initial"
    animate="animate">
        <div>
          <img src={process.env.PUBLIC_URL + `/images/logos/logo_2.png`}/>
        </div>
    </motion.span>
  );

  const Imagess4 = ({ title, disabled }) => (
    <motion.span 
    className="row-title"
    variants={disabled ? null : banner}
    initial="initial"
    animate="animate">
        <div>
          <img src={process.env.PUBLIC_URL + `/images/logos/logo_3.png`}/>
        </div>
    </motion.span>
  );


  const Imagess5 = ({ title, disabled }) => (
    <motion.span 
    className="row-title"
    variants={disabled ? null : banner}
    initial="initial"
    animate="animate">
        <div>
          <img src={process.env.PUBLIC_URL + `/images/logos/logo_4.png`}/>
        </div>
    </motion.span>
  );

  const Imagess6 = ({ title, disabled }) => (
    <motion.span 
    className="row-title"
    variants={disabled ? null : banner}
    initial="initial"
    animate="animate">
        <div>
          <img src={process.env.PUBLIC_URL + `/images/logos/logo_5.png`}/>
        </div>
    </motion.span>
  );

  const Imagess7 = ({ title, disabled }) => (
    <motion.span 
    className="row-title"
    variants={disabled ? null : banner}
    initial="initial"
    animate="animate">
        <div>
          <img src={process.env.PUBLIC_URL + `/images/logos/logo_6.png`}/>
        </div>
    </motion.span>
  );

  const Imagess8 = ({ title, disabled }) => (
    <motion.span 
    className="row-title"
    variants={disabled ? null : banner}
    initial="initial"
    animate="animate">
        <div>
          <img src={process.env.PUBLIC_URL + `/images/logos/logo_7.png`}/>
        </div>
    </motion.span>
  );
  
  const Imagess9 = ({ title, disabled }) => (
    <motion.span 
    className="row-title"
    variants={disabled ? null : banner}
    initial="initial"
    animate="animate">
        <div>
          <img src={process.env.PUBLIC_URL + `/images/logos/logo_9.png`}/>
        </div>
    </motion.span>
  );


  const AnimatedLetters = ({ title, disabled }) => (
    <motion.span 
    className="row-title"
    variants={disabled ? null : banner}
    initial="initial"
    animate="animate">
      {[...title].map((letter) => (
        <motion.span   variants={disabled ? null : letterAni} className="row-letter">{letter}</motion.span>
      ))}
    </motion.span>
  );

  
  const AnimatedLetters3 = ({ title, disabled }) => (
    <motion.span 
    className="row-title-3"
    variants={disabled ? null : banner}
    initial="initial"
    animate="animate">
      {[...title].map((letter) => (
        <motion.span   variants={disabled ? null : letterAni} className="row-letter">{letter}</motion.span>
      ))}
    </motion.span>
  );

  const AnimatedLetters2 = ({ title, disabled }) => (
    <motion.span 
    className="row-title-gray"
    variants={disabled ? null : banner}
    initial="initial"
    animate="animate">
      {[...title].map((letter) => (
        <motion.span   variants={disabled ? null : letterAni} className="row-letter">{letter}</motion.span>
      ))}
    </motion.span>
  );

  const BannerRowTopNew = ({ title }) => {
    return (
      <div className={"banner-row"}>
        <div className="">
          <AnimatedLetters title={title} />
        </div>
    
      </div>
    );
  };

  const BannerRowCenterNew = ({ title, playMarquee }) => {
    return (
      <div className={`banner-row marquee  ${playMarquee && "animate"}`}>
        <div className="marquee__inner luxury-m-titile">
        <AnimatedLetters title={title}  />
        </div>
      </div>
    );
  };

export default Crypto;