import React, { useState, useRef, useEffect  } from "react";
import { motion, useViewportScroll, useTransform } from "framer-motion";
import Carousel from 'react-bootstrap/Carousel';
import Footer from "../components/Footer";
import Header from "../components/Header";
import { useMediaQuery } from 'react-responsive'
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Masonry from '@mui/lab/Masonry';
import { styled } from '@mui/material/styles';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

const Label = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(0.5),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0,
}));

const banner = {
    animate: {
      transition: {
        delayChildren: 0.4,
        staggerChildren: 0.1,
      },
    },
  };
  
  const letterAni = {
    initial: { y: 400 },
    animate: {
      y: 0,
      transition: {
        ease: [0.6, 0.01, -0.05, 0.95],
        duration: 1,
      },
    },
  };


  const Images1 = ({ speed }) => {
    const { scrollYProgress } = useViewportScroll();
    const yValue = useTransform(scrollYProgress, [0, 1], [0, 200*speed]);
  
        return(
          <motion.div initial={{opacity:0}} animate={{opacity:1}} transition={{duration:1}} style={{y:yValue}}>
             <img className="img-100" src={process.env.PUBLIC_URL + `/images/meet_emma_bellnew.jpg`} />
          </motion.div>
        )
  };
  

  
  const Images3 = ({ speed }) => {
    const { scrollYProgress } = useViewportScroll();
    const yValue = useTransform(scrollYProgress, [0, 1], [0, 200*speed]);
  
        return(
          <motion.div initial={{opacity:0}} animate={{opacity:1}} transition={{duration:1}} style={{y:yValue}}>
             <img className="img-100" src={process.env.PUBLIC_URL + `/images/sustainable_2.jpg`} />
          </motion.div>
        )
  };





  const Ellice = ({ speed }) => {
    const { scrollYProgress } = useViewportScroll();
    const yValue = useTransform(scrollYProgress, [0, 1], [0, 200*speed]);
  
        return(
          <motion.div initial={{opacity:0}} animate={{opacity:1}} transition={{duration:1}} style={{y:yValue}}>
             <img className="img-100" src={process.env.PUBLIC_URL + `/images/smalll.jpg`} />
          </motion.div>
        )
  };
  

function Rebuild() {


  const [countryNameMap, setCountryNameMap] = useState([]);

  const [BoxTypeOfHolidayValue, setBoxTypeOfHolidayValue] = useState([]);
  const [typeOfHoliday, setTypeOfHoliday] = useState(true);

  const [CountryPname, sethandleCountryPname] = useState(0);
  const [adults, setadults] = useState(0);
  const [children,setchildren] = React.useState('');  

  const handleCountryPname = (event) => {
    sethandleCountryPname(event.target.value);
  }



  const handleChangeadults = (event) => {

    console.log(event);
    setadults(event.target.value);
  };
  

  const handleChangechildren = (event) => {
    setchildren(event.target.value);
  };



  const checkBoxTypeOfHoliday = (event) => {
    let newArray3 = [...BoxTypeOfHolidayValue, event.target.value, ];
    if (BoxTypeOfHolidayValue.includes(event.target.value)) {
    newArray3 = newArray3.filter(day2 => day2 !== event.target.value);
  }

  setBoxTypeOfHolidayValue(newArray3)

  if (newArray3.length > 0) {
    setTypeOfHoliday(false);
  } else {
    setTypeOfHoliday(true);
  }

  }


  useEffect(()=>{

    fetch('https://www.ovholidays.com/experience/get-country-all.php', {
      method: 'GET',
      headers: {
          "Content-Type": "application/json"
      }
       }).then((response) => response.json())
      .then(data => 
        
        setCountryNameMap(data)
        // console.log(data)
        )
      .catch((error) => {
          console.log('not work');
          console.log(error);
      });



  },[])



  // validation start
 const [fname, setFName] = useState('');
 const [lname, setLName] = useState('');
 const [email, setEmail] = useState('');
 const [phone, setPhone] = useState('');
 const [whatsapp, setWhatsapp] = useState('');

 const [FNameArray, setFNameArray] = useState([]);
 const [LNameArray, setLNameArray] = useState([]);
 const [emailArray, setEmailArray] = useState([]);
 const [phoneArray, setPhoneArray] = useState([]);
 const [whatsappArray, setWhatsappArray] = useState([]);



 const FnameChange = (event) => {
   setFName(event.target.value);
 }
 const LnameChange = (event) => {
  setLName(event.target.value);
}

 const emailChange = (event) => {
   setEmail(event.target.value);
 }

 const phoneChange = (event) => {
  setPhone(event.target.value);
}

const whatsappChange = (event) => {
  setWhatsapp(event.target.value);
}

const [isActive, setActive] = useState(false);

const formSubmitHandler = (event) => {

  setActive(true)
  event.preventDefault();
  
  let return_status = true;
  let newList = [];
  let newListbudgect = [];
  let FNamevalue = [];
  let LNamevalue = [];
  let Emailvalue = [];
  let Phonevalue = [];
  let Whatsappvalue = [];
  let Massagevalue = [];

  if(fname == ""){
    FNamevalue.push(`Full Name Required`);
    return_status = false;
    setActive(false)
  }

  if(lname == ""){
    LNamevalue.push(`Travel Agency / Tour Operator Name Name Required`);
    return_status = false;
    setActive(false)
  }


  if(email == ""){
    Emailvalue.push(`Valid Email Address Required`);
    return_status = false;
    setActive(false)
  } else {
    var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

    if (!pattern.test(email)) {
        Emailvalue.push(`Please enter valid email Address`);
        return_status = false;
        setActive(false)
      }
  }
 
  if(phone == ""){
    Phonevalue.push(`Company Number required`);
    return_status = false;
    setActive(false)
  }

  if(whatsapp  == ""){
    Whatsappvalue.push(`Whatsapp Number required`);
    return_status = false;
    setActive(false)
  }
 
 

  setFNameArray(FNamevalue);
  setLNameArray(LNamevalue);
  setEmailArray(Emailvalue);
  setPhoneArray(Phonevalue);
  setWhatsappArray(Whatsappvalue);
 

  
  if (return_status) {
    fetch('https://www.viluxurholidays.com/thank-you-vh.php', {
      method: 'POST',
      headers: {
          "Content-Type": "application/json"
      },
      body: JSON.stringify({
      
        fldCustomerFormMessage1 : "Viluxur Form",
      
        fldCustomerFName : fname,
        fldTravelAgancyName : lname,
        fldCustomerEMail : email,
        fldCustomerPhone : phone,
        fldCustomerWhatsapp : whatsapp,
        fldCountryName:CountryPname,
        fldAdults:adults,
        fldChildren:children,
        fldtypeOfHoliday:typeOfHoliday


      

       
      })
  }).then((response) => response.json())
      .then( data =>{
          
          if (data.code == 200) {
            window.location.href = 'https://www.ovholidays.com/thankyou.php';
          } else {
            window.location.href = 'https://www.ovholidays.com/errorovx.php';
          }
        }).catch((error) => {
          console.log('not work');
          console.log(error);
      });
  }  
}
// validation end

 

  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 1224px)'
  })
  const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
  const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })



  return (
    <div>
        <Header/>
        {isDesktopOrLaptop && 
          <div className="page-top-space we-are-future">
       <section className="widh-90">
        <div className="row">
            <div className="col-12">
            <Carousel className="local-carasol" fade>
          <Carousel.Item>
        <img
          className="d-block w-100"
          src={process.env.PUBLIC_URL + `/images/Coral_Planting_banner-new.jpg`}
          alt="First slide"
        />
        <Carousel.Caption>
        <div className="we-got-you-2">
            <div>
            <h1 className="title-2 text-uppercase color-white">We Are <br/>The Future</h1>
            <p className="para color-white">We at Viluxur Holidays strongly believe that we are the future of the travel and tourism industry.  </p>
            </div>
          </div>
         </Carousel.Caption>
         </Carousel.Item>
         <Carousel.Item>
        <img
          className="d-block w-100"
          src={process.env.PUBLIC_URL + `/images/Coral_Planting.jpg`}
          alt="Second slide"
        />
        <Carousel.Caption>
        <div className="we-got-you-2">
            <div>
            <h1 className="title-2 text-uppercase color-white">We Are <br/>The Future</h1>
            <p className="para color-white">We at Viluxur Holidays strongly believe that we are the future of the travel and tourism industry.</p>
            </div>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
   
    </Carousel>
            </div>
        </div>
  </section>


  <section className="container-fluid mt-8">
            <div className="row">
            <div className="col-12">
           <div className="we-got-you-2 left-future">
            <div>
              <p className="para">Having a proverbial footprint around the globe, we hope to increase our borders whilst simultaneously rebuilding our brand’s infrastructure to mimic that of the 2023 Compendium goals for Tourism 2023 which includes; developing or upgrading infrastructure for tourism purposes aimed at increasing the number of (foreign) visitors, creating (direct or indirect) job opportunities, establishing or improving supply chains, with the superior objective “to support economic development and human well-being, with a focus on affordable and equitable access for all.” 
              </p>

              <p className="para">The company is also moving towards sustainable tourism with the aim of generating revenues from tourism and the motivation to attract more tourists which can then trigger the willingness of local authorities to invest in urban infrastructure.
              </p>
            </div>
          </div>
        </div>
    </div>
  </section>
  
  <section className="container-fluid future-second">
      <div className="row alind-end mrgn-mins-top">
          <div className="col-3 ">
          <Images3  speed={0}/>
          </div>
          <div className="col-2">
          </div>
          <div className="col-7 z-index-min">
          <Images1 speed={0}/>
          </div>
           </div>
          <div className="row">
            <div className="col-12">
            <div className="image-container">
              <img className="img-svg rotate-left" src={process.env.PUBLIC_URL + `/images/We-Are-The-Future-2.svg`} />
            </div>
            </div>
          </div>
  </section>


      <section className="container-fluid qute-icon-m-top">
            <div className="row">
            <div className="col-12">

           <div className="we-got-you-2 the-end-goal">
            <div>
           
              <h2 className="title-2 ">Meet Emma Bell</h2>
              <p className="para ">A certified Marine Biologist, Emma joins the Group having worked first hand in the Maldives at some of the islands’ most renowned resorts. She is here to help guide us as we embark on this new, green promise. 
            </p>
            <p className="para ">Through Emma, Viluxur monitors closely with our resort partners to help us chose only the best and most transparent resorts to pledge for a conscious tomorrow. 
            </p>
            </div>
          </div>
        </div>
    </div>

  </section>

  <section className="container-fluid mb-5-new">

<div className="row ">

  <div className="col-12">
  <div className="we-got-you-4">
          <div>
          <h2 className="title-2 ">We Promise You The Ocean</h2>
            <p className="para ">At Viluxur, our end goal is to keep the ocean, its flora and fauna we know and love so dearly, safe. Given that the organization predominantly focusses on travel through the Indian Ocean, Viluxur and its subsidiaries through travel conglomerate Luxury Alliance pledge to protect the Indian Ocean through various sustainable programme. 
            </p>

          </div>
        </div>
  </div>

</div>

</section>

    <section className="container-fluid ">
          <div className="row">
            <div className="col-12">
            <motion.img className="width-100" src={process.env.PUBLIC_URL + `/images/wearethefuturefullsizebanner.jpg`} />
            </div>
          </div>
    </section>


<div className="m-xy-s mt-8">
        <div className="row">
          <div className="col-7">
         
          </div>
          <div className="col-5 ">
          <div className="">
            <div className="width-600">
            <h2 className="title-2 ">We Know Our Stuff  </h2>
              <p className="para  ">With big corporations misusing the word ‘sustainability’ and instead greenwashing the entire cause, we at Viluxur decided that as we would pledge towards a sustainable travel industry once we did our real research. 
              </p>
            </div>
          </div>
        </div>
        </div>
    </div>
    <div className="container-fluid mrgn-mins-top-prep">
        <div className="row">
              <div className="col-6 p-0">
              <motion.img className="width-100" src={process.env.PUBLIC_URL + `/images/turtlebanner_2.jpg`} />
              </div> 
        </div>     
  </div>



<section className="container">
<div className="row margin-top-and-botm">
          <div className="col-12 ">
          <div className=" ">
            <div>
              <h2 className="title-2 text-center">A True, Blue Tomorrow </h2>
              <p className="para text-center">Here are just a few of the Maldives most prominent sustainability programmes.
              </p>     
            </div>
          </div>
          </div>
        </div>
        <div className="row">
          <div className="col-4 pdn-30">

          <motion.img className="width-100" src={process.env.PUBLIC_URL + `/images/Olive-Ridley-Project-new.jpg`} />
          </div>
          <div className="col-4 pdn-30">
          <motion.img className="width-100" src={process.env.PUBLIC_URL + `/images/Maldives-Whale-Shark-new.jpg`} />
          </div>
          <div className="col-4 pdn-30">
          <motion.img className="width-100" src={process.env.PUBLIC_URL + `/images/MANTA_TRUST-new.jpg`} />
          </div>
</div>
</section>

<section className="container">
<div className="row margin-top-and-botm">
          <div className="col-12 ">
          <div className=" ">
            <div>
              <h2 className="title-2 text-center">Are Your Customers Looking For Purposeful Travel To The Maldives?</h2>
              <p className="para text-center">Allow Viluxur to curate the best packages with our resort partners that will not only teach them about the endangered species found in the Maldives but also allow them to partake in the change being made. 
              </p> 
  
            </div>
          </div>
          </div>
        </div>
   
</section>

<section className="container">
          <div className="row">
              <div className="col-12 ">
              <form className="mt-5-form">
          <div className="row input-style">
            <div className="col-6">
            <p className="para-form"> Full Name:</p>
            <input type="text"  autoComplete="off" name="fldCustomerFName" onChange={FnameChange} />
            <div className="error-waper">
            <div className="erroe-msg">{FNameArray[0]}</div>
          </div>
          </div>
            <div className="col-6">
            <p className="para-form"> Travel Agency / Tour Operator Name</p>
            <input type="text"  autoComplete="off" name="fldCustomerLName" onChange={LnameChange} />
            <div className="error-waper">
            <div className="erroe-msg">{LNameArray[0]}</div>
          </div>
          </div>
          </div>

            
          <div className="row input-style">
            <div className="col-6">
            <p className="para-form"> Country Of Origin </p>
            <select className="width-100 outline-none"
              labelId="country_id"
              id="country_id"
              defaultValue={""}
              onChange={handleCountryPname}
              name="fldCustomerFormResort1ID"
            >

              {countryNameMap.map(item=> (
                <option value={item.fldCountryName}>{item.fldCountryName}</option>
              ))}
            </select>
            </div>
            <div className="col-6">
            <p className="para-form"> Email:</p>
            <input type="text"  name="fldCustomerEmail" onChange={emailChange}/>
            <div className="error-waper">
            <div className="erroe-msg">{emailArray[0]}</div>
            </div>
            </div>
          </div>

            <div className="row input-style">
            <div className="col-6">
            <p className="para-form"> Company Number </p>
            <input type="text" name="fldCustomerPNumber" onChange={phoneChange}/>
            <div className="error-waper">
            <div className="erroe-msg">{phoneArray[0]}</div>
            </div>
            </div>
            <div className="col-6">
            <p className="para-form"> Whatsapp Number </p>
            <input type="text" name="fldCustomerPNumber" onChange={whatsappChange}/>
            <div className="error-waper">
            <div className="erroe-msg">{whatsappArray[0]}</div>
          </div>
            </div>
            
          </div>

          <div className="row input-style">
            <div className="row">
                  <div className="col-6">
                  <p className="para-form mb-20">Number Of Pax</p>
                  </div>
                  <div className="col-6">
                  <p className="para-form mb-20">Mice Or Leisure Travel?</p>
                  </div>
            </div>
            <div className="col-6 plr-3 flex-align-center">
                           <select 
                            className="nightSelect mr-right-form outline-none "
                              labelId="adults"
                              id="adults"
                              value={adults}
                              name="fldCustomerFormadults"
                              onChange={handleChangeadults}
                            >
                                <option  value={"2 Adults"}>1 Adults</option>
                                <option  value={"2 Adults"}>2 Adults</option>
                                <option value={"3 Adults"}>3 Adults</option> 
                                <option value={"4 Adults"}>4 Adults</option>
                                <option value={"5 Adults"}>5 Adults</option>
                                <option value={"6 Adults"}>6 Adults</option>
                                <option value={"7 Adults"}>7 Adults</option>
                                <option value={"8 Adults"}>8 Adults</option> 
                                <option value={"9 Adults"}>9 Adults</option>
                                <option value={"10 Adults"}>10 Adults</option>
                            </select>

                           <select 
                            className="nightSelect outline-none"
                              labelId="adults"
                              id="adults"
                              value={children}
                              name="fldCustomerFormadults"
                              onChange={handleChangechildren}
                            >
                                <option value={"0 children"}>0 Children</option>
                                <option value={"1 children"}>1 Child</option>
                                <option value={"2 children"}>2 Children</option> 
                                <option value={"3 children"}>3 Children</option>
                                <option value={"4 children"}>4 Children</option>
                            </select>
            </div>
            <div className="col-6">
            
            <FormGroup className="display-inline-row">
            <FormControlLabel className="form-checkpoint" onChange={checkBoxTypeOfHoliday} control={<Checkbox  name="fldCustomerFormTypeOFMice" value="Mice" />} label="Mice"/>
            <FormControlLabel className="form-checkpoint" onChange={checkBoxTypeOfHoliday} control={<Checkbox name="fldCustomerFormTypeOFLeisureTravel" value="Leisure Travel"  /> } label="Leisure Travel"/>
            </FormGroup>
            </div>
          
          </div>
          <div className="row input-style mt-50">
          <button className="submit-btn" type="submit" value="Submit" onClick={formSubmitHandler} >Submit</button>
          </div>
          
        </form>
              </div>
          </div>
</section>



    {/* <section className="container">
    <div className="row margin-top-and-botm ">
          <div className="col-12 ">
          <div className=" ">
            <div>
              <h2 className="title-2 text-center">Our strongest aspect is our multicultural team. We are a part of a large global family. By global, we mean the individuals who are part of the Viluxur team.</h2>
             
            </div>
          </div>
          </div>
         
        </div>

        <div className="row ">
            <div className="col-3 pdn-50-left-r">
            <img className="width-100" src={process.env.PUBLIC_URL + `/images/meet-team/SHAFFI.jpg`} />
            <div className="content-inner padin-20">
                 
                  <h3 className="name"><span className="font-bembo">Shafraz Fazley</span></h3>
               
                </div>
            </div>
            <div className="col-3 pdn-50-left-r">
            <img className="width-100 " src={process.env.PUBLIC_URL + `/images/meet-team/ELLICE.jpg`} />
            <div className="content-inner padin-20">
                 
                  <h3 className="name"><span className="font-bembo">Ellice Bugayong</span></h3>
               
                </div>
            </div>
            <div className="col-3 pdn-50-left-r">
            <img className=" width-100" src={process.env.PUBLIC_URL + `/images/meet-team/HYDEE.jpg`} />
            <div className="content-inner padin-20">
                 
                  <h3 className="name "><span className="font-bembo">Haydee </span></h3>
               
                </div>
            </div>
            <div className="col-3 pdn-50-left-r">
            <img className=" width-100" src={process.env.PUBLIC_URL + `/images/meet-team/HYDEE.jpg`} />
            <div className="content-inner padin-20">
                 
                  <h3 className="name "><span className="font-bembo">Haydee </span></h3>
               
                </div>
            </div>
        </div>
        <div className="row ">
            <div className="col-3 pdn-50-left-r">
            <img className="width-100" src={process.env.PUBLIC_URL + `/images/meet-team/SHAFFI.jpg`} />
            <div className="content-inner padin-20">
                 
                  <h3 className="name"><span className="font-bembo">Shafraz  Fazley</span></h3>
               
                </div>
            </div>
            <div className="col-3 pdn-50-left-r">
            <img className="width-100 " src={process.env.PUBLIC_URL + `/images/meet-team/ELLICE.jpg`} />
            <div className="content-inner padin-20">
                 
                  <h3 className="name"><span className="font-bembo">Ellice Bugayong</span></h3>
               
                </div>
            </div>
            <div className="col-3 pdn-50-left-r">
            <img className=" width-100" src={process.env.PUBLIC_URL + `/images/meet-team/HYDEE.jpg`} />
            <div className="content-inner padin-20">
                 
                  <h3 className="name "><span className="font-bembo">Haydee </span></h3>
               
                </div>
            </div>
            <div className="col-3 pdn-50-left-r">
            <img className=" width-100" src={process.env.PUBLIC_URL + `/images/meet-team/HYDEE.jpg`} />
            <div className="content-inner padin-20">
                 
                  <h3 className="name "><span className="font-bembo">Haydee </span></h3>
               
                </div>
            </div>
        </div>
    </section> */}  
          </div>
        }
       
       {isTabletOrMobile && 
        <div className="page-space-top-mobile team-mobile rebuild">
        <motion.div
              initial={{ opacity: 0, y: 180 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{
                ease: "easeInOut",
                duration: 1,
                delay: 0.6,}}
                className=""
              >


<section className="container-fluid min-zindex">
  <div className="row">
    <div className="col-12">
    <Carousel className="local-carasol min-zindex" fade>
          <Carousel.Item>
        <img
          className="d-block w-100"
          src={process.env.PUBLIC_URL + `/images/Coral_Planting_banner-new.jpg`}
          alt="First slide"
        />
        <Carousel.Caption>
        <div className="we-got-you-2">
            <div>
            
         
        <h1 className="title-2 text-uppercase">We Are <br/>Local Global </h1>
        <p className="para ">We work closely with our destination partners to ensure the establishment of luxurious gateways and support services connecting new markets and destinations. </p>

              
            </div>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={process.env.PUBLIC_URL + `/images/Coral_Planting.jpg`}
          alt="Second slide"
        />

        <Carousel.Caption>
        <div className="we-got-you-2">
            <div>
            
             
        <h1 className="title-2 text-uppercase">We Are <br/>Local Global </h1>
        <p className="para">We work closely with our destination partners to ensure the establishment of luxurious gateways and support services connecting new markets and destinations. </p>

              
            </div>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
   
    </Carousel>
    </div>
  </div>
</section>

        <div className="we-got-you">
      <div>
    
     
      <h1 className="title-2 text-uppercase">We Are <br/>The Future</h1>
        <p className="para ">We at Viluxur Holidays strongly believe that <br/>we are the future of the travel and tourism industry.</p>

        <p className="para">Having a proverbial footprint around the globe, we hope to increase our borders whilst simultaneously rebuilding our brand’s infrastructure to mimic that of the 2023 Compendium goals for Tourism 2023 which includes; developing or upgrading infrastructure for tourism purposes aimed at increasing the number of (foreign) visitors, creating (direct or indirect) job opportunities, establishing or improving supply chains, with the superior objective “to support economic development and human well-being, with a focus on affordable and equitable access for all.” 
              </p>
      </div>
     </div>





    <section>
<div className="row alind-end mrgn-mins-top ">
    <div className="col-5 z-index-min">
   
    
    </div>
   
    <div className="col-7 ">
   
    <Images3  speed={0}/>
   
    </div>
    
  </div>
  
    <div className="row">
      <div className="col-5">

      </div>
      <div className="col-7">
        <div className="image-container">
        <img className="img-svg" src={process.env.PUBLIC_URL + `/images/We-Are-The-Future-2.svg`} />
        </div>
      
      </div>
    </div>
</section>


<section className="container margn-top-20">
    <div className="row ">
          <div className="col-12 ">
          <div className="pdn-lr-30 ">
            <div>
              <p className="para">The company is also moving towards sustainable tourism with the aim of generating revenues from tourism and the motivation to attract more tourists which can then trigger the willingness of local authorities to invest in urban infrastructure.
              </p>  
            </div>
          </div>
          </div>
        </div>
    </section>


    <section className="container-fluid  mt-8">
        <div className="row">
          <div className="col-12 zindex-min">
              <Images1 speed={0}/>
          </div>
       </div>
</section>

<section className="container-fluid">
            <div className="row">
              <div>
              <div className="we-got-you-2 ">
            <div>
           
            <h2 className="title-2 ">Meet Emma Bell</h2>
              <p className="para ">A certified Marine Biologist, Emma joins the Group having worked first hand in the Maldives at some of the islands’ most renowned resorts. She is here to help guide us as we embark on this new, green promise. 
            </p>
            <p className="para ">Through Emma, Viluxur monitors closely with our resort partners to help us chose only the best and most transparent resorts to pledge for a conscious tomorrow. 
            </p>
            </div>
          </div>
              </div>
            </div>
</section>


<section className="container-fluid mt-8">
          <div className="row">
            <div className="col-12">
            <motion.img className="width-100" src={process.env.PUBLIC_URL + `/images/wearethefuturefullsizebanner.jpg`} />
            </div>
          </div>
</section>



<section className="container-fluid">
        <div className="row">
              <div>
              <div className="we-got-you-2 ">
              <div>
                <h2 className="title-2 ">We Promise You The Ocean</h2>
                <p className="para ">At Viluxur, our end goal is to keep the ocean, its flora and fauna we know and love so dearly, safe. Given that the organization predominantly focusses on travel through the Indian Ocean, Viluxur and its subsidiaries through travel conglomerate Luxury Alliance pledge to protect the Indian Ocean through various sustainable programme.
              </p>
            </div>
          </div>
          </div>
     </div>
</section>


<section className="container-fluid mt-8">
          <div className="row">
            <div className="col-12">
            <motion.img className="width-100" src={process.env.PUBLIC_URL + `/images/turtlebanner_2.jpg`} />
            </div>
          </div>
</section>



<section className="container-fluid">
        <div className="row">
              <div>
              <div className="we-got-you-2 ">
              <div>
              <h2 className="title-2 ">We Know Our Stuff  </h2>
              <p className="para  ">With big corporations misusing the word ‘sustainability’ and instead greenwashing the entire cause, we at Viluxur decided that as we would pledge towards a sustainable travel industry once we did our real research. 
              </p>
            </div>
          </div>
          </div>
     </div>
</section>


    
<section className="container pdn-lr-30 mt-8">
<div className="row margin-top-and-botm">
          <div className="col-12">
          <div className=" ">
            <div>
              <h2 className="title-2 text-center">A True, Blue Tomorrow </h2>
              <p className="para text-center">Here are just a few of the Maldives most prominent sustainability programmes.
              </p>     
            </div>
          </div>
          </div>
        </div>

        <div className="row  mb-3">
        <Carousel fade>
      <Carousel.Item>
        <img
          className="d-block width-100"
          src={process.env.PUBLIC_URL + `/images/Olive-Ridley-Project-new.jpg`}
          alt="First slide"
        />
        <Carousel.Caption>
     
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block width-100"
          src={process.env.PUBLIC_URL + `/images/Maldives-Whale-Shark-new.jpg`}
          alt="Second slide"
        />
        <Carousel.Caption>
      
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block width-100"
          src={process.env.PUBLIC_URL + `/images/MANTA_TRUST-new.jpg`}
          alt="Second slide"
        />
        <Carousel.Caption>
        
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
        </div>
    
</section>



<section className="container mt-8 mb-3">
          <div className="row">
              <div className="col-12 bg-white pdn-30-form">

              <h2 className="title-2 text-center">Are Your Customers Looking For Purposeful Travel To The Maldives?</h2>
              <p className="para text-center">Allow Viluxur to curate the best packages with our resort partners that will not only teach them about the endangered species found in the Maldives but also allow them to partake in the change being made. 
              </p> 

            
              <form className="mt-5-form">
          <div className="row input-style">
            <div className="col-12">
            <p className="para-form"> Full Name:</p>
            <input type="text"  autoComplete="off" name="fldCustomerFName" onChange={FnameChange} />
            <div className="error-waper">
            <div className="erroe-msg">{FNameArray[0]}</div>
          </div>
          </div>
            <div className="col-12">
            <p className="para-form"> Travel Agency / Tour Operator Name</p>
            <input type="text"  autoComplete="off" name="fldCustomerLName" onChange={LnameChange} />
            <div className="error-waper">
            <div className="erroe-msg">{LNameArray[0]}</div>
          </div>
          </div>
          </div>

            
          <div className="row input-style">
            <div className="col-12">
            <p className="para-form"> Country Of Origin </p>
            <select className="width-100 outline-none"
              labelId="country_id"
              id="country_id"
              defaultValue={""}
              onChange={handleCountryPname}
              name="fldCustomerFormResort1ID"
            >

              {countryNameMap.map(item=> (
                <option value={item.fldCountryName}>{item.fldCountryName}</option>
              ))}
            </select>
            </div>
            <div className="col-12">
            <p className="para-form"> Email:</p>
            <input type="text"  name="fldCustomerEmail" onChange={emailChange}/>
            <div className="error-waper">
            <div className="erroe-msg">{emailArray[0]}</div>
            </div>
            </div>
          </div>

            <div className="row input-style">
            <div className="col-12">
            <p className="para-form"> Company Number </p>
            <input type="text" name="fldCustomerPNumber" onChange={phoneChange}/>
            <div className="error-waper">
            <div className="erroe-msg">{phoneArray[0]}</div>
            </div>
            </div>
            <div className="col-12">
            <p className="para-form"> Whatsapp Number </p>
            <input type="text" name="fldCustomerPNumber" onChange={whatsappChange}/>
            <div className="error-waper">
            <div className="erroe-msg">{whatsappArray[0]}</div>
          </div>
            </div>
            
          </div>

          <div className="row input-style">
          
            <div className="col-12 plr-3">
            <p className="para-form mb-20">Number Of Pax</p>
            <div className="flex-align-center">
            <select 
                            className="nightSelect mr-right-form outline-none "
                              labelId="adults"
                              id="adults"
                              value={adults}
                              name="fldCustomerFormadults"
                              onChange={handleChangeadults}
                            >
                                <option  value={"2 Adults"}>1 Adults</option>
                                <option  value={"2 Adults"}>2 Adults</option>
                                <option value={"3 Adults"}>3 Adults</option> 
                                <option value={"4 Adults"}>4 Adults</option>
                                <option value={"5 Adults"}>5 Adults</option>
                                <option value={"6 Adults"}>6 Adults</option>
                                <option value={"7 Adults"}>7 Adults</option>
                                <option value={"8 Adults"}>8 Adults</option> 
                                <option value={"9 Adults"}>9 Adults</option>
                                <option value={"10 Adults"}>10 Adults</option>
                            </select>

                           <select 
                            className="nightSelect outline-none"
                              labelId="adults"
                              id="adults"
                              value={children}
                              name="fldCustomerFormadults"
                              onChange={handleChangechildren}
                            >
                                <option value={"0 children"}>0 Children</option>
                                <option value={"1 children"}>1 Child</option>
                                <option value={"2 children"}>2 Children</option> 
                                <option value={"3 children"}>3 Children</option>
                                <option value={"4 children"}>4 Children</option>
                            </select>
            </div>
                           
            </div>
            <div className="col-12">
            <p className="para-form mb-20">Mice Or Leisure Travel?</p>
              <div>
              <FormGroup className="display-inline-row">
               <FormControlLabel className="form-checkpoint" onChange={checkBoxTypeOfHoliday} control={<Checkbox  name="fldCustomerFormTypeOFMice" value="Mice" />} label="Mice"/>
                <FormControlLabel className="form-checkpoint" onChange={checkBoxTypeOfHoliday} control={<Checkbox name="fldCustomerFormTypeOFLeisureTravel" value="Leisure Travel"  /> } label="Leisure Travel"/>
            </FormGroup>
              </div>
            
         
            </div>
          
          </div>
          <div className="row input-style mt-50">
          <button className="submit-btn" type="submit" value="Submit" onClick={formSubmitHandler} >Submit</button>
          </div>
          
        </form>  
              </div>
          </div>
</section>

  
      </motion.div>
          </div>
       }
            
        
        
        <Footer/>
    </div>
  );
}

const BannerRowTop = ({ title }) => {
    return (
      <div className={"banner-row"}>
        <div className="">
          <AnimatedLetters title={"We're"} />
          <AnimatedLetters2 className="gray-font" title={'Local'} />
          <AnimatedLetters title={'Global'} />
        </div>
      </div>
    );
  };
  
  const BannerRowBottom = ({ title }) => {
    return (
      <div className={"banner-row center ml-5"}>
              <motion.div
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{ ease: [0.6, 0.01, -0.05, 0.95], duration: 1, delay: 1 }} >
        </motion.div>
        <AnimatedLetters title={title} />
      </div>
    );
  };
  
  const BannerRowCenter = ({ title, playMarquee }) => {
    return (
      <div className={`banner-row marquee  ${playMarquee && "animate"}`}>
        <div className="marquee__inner luxury-m-titile">
        <AnimatedLetters title={title}  />
        </div>
      </div>
    );
  };

  const AnimatedLetters = ({ title, disabled }) => (
    <motion.span 
    className="row-title"
    variants={disabled ? null : banner}
    initial="initial"
    animate="animate">
      {[...title].map((letter) => (
        <motion.span   variants={disabled ? null : letterAni} className="row-letter">{letter}</motion.span>
      ))}
    </motion.span>
  );


  const AnimatedLetters2 = ({ title, disabled }) => (
    <motion.span 
    className="row-title-gray row-title"
    variants={disabled ? null : banner}
    initial="initial"
    animate="animate">
      {[...title].map((letter) => (
        <motion.span   variants={disabled ? null : letterAni} className="row-letter">{letter}</motion.span>
      ))}
    </motion.span>
  );


  const BannerRowTopNew = ({ title }) => {
    return (
      <div className={"banner-row"}>
        <div className="">
          <AnimatedLetters title={title} />
        </div>
    
      </div>
    );
  };

  const itemData = [
    {
      img: 'https://www.viluxurholidays.com/images/new-viluxur/team/PG_8.jpg',
     
    },
    {
      img: 'https://www.viluxurholidays.com/images/new-viluxur/team/PG_11T.jpg',
      
    },
    {
      img: 'https://www.viluxurholidays.com/images/new-viluxur/team/PG_9.jpg',
     
    },
    {
      img: 'https://www.viluxurholidays.com/images/new-viluxur/team/PG_12T.jpg',
      
    },
   
    {
      img: 'https://www.viluxurholidays.com/images/new-viluxur/team/PG_13T.jpg',
      
    },
    
    {
      img: 'https://www.viluxurholidays.com/images/new-viluxur/team/PG_10.jpg',
     
    },
    {
      img: 'https://www.viluxurholidays.com/images/new-viluxur/team/PG_14T.jpg',
      
    },
    {
      img: 'https://www.viluxurholidays.com/images/new-viluxur/team/PG_5.jpg',
      
    },
    {
      img: 'https://www.viluxurholidays.com/images/new-viluxur/team/PG_15T.jpg',
      
    },
    
    {
      img: 'https://www.viluxurholidays.com/images/new-viluxur/team/PG_16T.jpg',
  
    },
    {
      img: 'https://www.viluxurholidays.com/images/new-viluxur/team/PG_3.jpg',
    
    },
    {
      img: 'https://www.viluxurholidays.com/images/new-viluxur/team/PG_11.jpg',
    
    },
   
  
  
  ];


export default Rebuild;