
import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
const Middle = () => {

  const [playMarquee, setPlayMarquee] = useState(false);
  useEffect(() => {
    setPlayMarquee(true);

  }, []);
  return (

    <motion.div
initial={{ opacity: 0, y: 180 }}
animate={{ opacity: 1, y: 0 }}
transition={{
  ease: "easeInOut",
  duration: 1,
  delay: 0.6,}}
  className=""
>



    <motion.div className="Middle">
     <div className="we-got-you width-800">
      <div>
    
        <h2 className="title-2">Discover Maldives, Discover Viluxur </h2>
        <p className="para width-para-s">Discover the world’s most revered luxury brands found amidst the 26 atolls of the Maldives. Find luxury villas, renowned resort chains, premier yacht services and more to curate your clients’ next destination getaway.  </p>
        <p className="para width-para-s">
Viluxur has over 6,000 agents across the world who can now access the ultra- private and completely off-market properties found solely in the Maldives. Allow our teams of specialists to assist you in building the ultimate portfolio when it comes to VIP luxury travel services. 
</p>
      </div>
     
     </div>
     
    </motion.div>

    </motion.div>
  );
};




export default Middle;


