import React, { useState, useRef, useEffect } from "react";
import { motion, useViewportScroll, useTransform } from "framer-motion";
import Carousel from 'react-bootstrap/Carousel';
import Footer from "../components/Footer";
import Header from "../components/Header";
import { useMediaQuery } from 'react-responsive'
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Masonry from '@mui/lab/Masonry';
import { styled } from '@mui/material/styles';

const Label = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(0.5),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0,
}));

const banner = {
  animate: {
    transition: {
      delayChildren: 0.4,
      staggerChildren: 0.1,
    },
  },
};

const letterAni = {
  initial: { y: 400 },
  animate: {
    y: 0,
    transition: {
      ease: [0.6, 0.01, -0.05, 0.95],
      duration: 1,
    },
  },
};


const Ellice = ({ speed }) => {
  const { scrollYProgress } = useViewportScroll();
  const yValue = useTransform(scrollYProgress, [0, 1], [0, 200 * speed]);

  return (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1 }} style={{ y: yValue }}>
      <img className="img-100" src={process.env.PUBLIC_URL + `/images/smalll.jpg`} />
    </motion.div>
  )
};


function Team() {


  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 1224px)'
  })
  const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
  const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })



  return (
    <div>
      <Header />
      {isDesktopOrLaptop &&
        <div className="page-top-space">
          <section className="widh-90">
            <div className="row">
              <div className="col-12">
                <Carousel className="local-carasol" fade>
                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src={process.env.PUBLIC_URL + `/images/loca-global-banner.jpg`}
                      alt="First slide"
                    />
                    <Carousel.Caption>
                      <div className="we-got-you-2">
                        <div>


                          <h1 className="title-2 text-uppercase">We Are <br />Local Global </h1>
                          <p className="para ">We work closely with our destination partners to ensure the establishment of luxurious gateways and support services connecting new markets and destinations. </p>


                        </div>
                      </div>
                    </Carousel.Caption>
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src={process.env.PUBLIC_URL + `/images/chaina_staff_img.jpg`}
                      alt="Second slide"
                    />

                    <Carousel.Caption>
                      <div className="we-got-you-2">
                        <div>


                          <h1 className="title-2 text-uppercase">We Are <br />Local Global </h1>
                          <p className="para">We work closely with our destination partners to ensure the establishment of luxurious gateways and support services connecting new markets and destinations. </p>


                        </div>
                      </div>
                    </Carousel.Caption>
                  </Carousel.Item>

                </Carousel>
              </div>
            </div>
          </section>


          <div className="container">
            <div className="row margin-top-and-botm">
              <div className="col-12 ">
                <div className=" ">
                  <div>
                    <h2 className="title-2 text-center">Meet The Team That Makes It All Possible  </h2>
                    <p className="para text-center">Our team of travel experts are located across the globe with unique experiences and are at the core of our operation as an industry leader. As a company that caters to the whole world, we understand the importance of good communication and having a diverse worldview.
                    </p>

                    <p className="para text-center">This is why our team is multinational and well-exposed to the journeys we guide our customers through. <br /> It is the distinct charm of the Viluxur experience.
                    </p>
                  </div>
                </div>
              </div>

            </div>
            <div className="custom-row ">
              <div className="grid-item item1">
                <img className="width-100" src={process.env.PUBLIC_URL + `/images/meet-team/SHAFFI.jpg`} />
                <div className="content-inner padin-20">
                  <p className="para">As the Managing Director of Viluxur Holidays, Shafraz Fazley is the strategic mind driving the company forward. He envisions and meticulously designs the business's structure and global positioning, aiming to make clients proud partners. While constantly seeking innovative solutions, Shafraz favors steady, evolutionary changes over rapid, exponential shifts. His leadership is focused on creating a lasting impact in the luxury travel industry, guiding Viluxur Holidays towards a prestigious global presence with a commitment to excellence and sustainable growth.</p>
                  <h3 className="name"><span className="font-bembo">Shafraz Fazley</span></h3>

                </div>
              </div>
              <div className="grid-item item2">
                <img className="width-100" src={process.env.PUBLIC_URL + `/images/meet-team/Cammy.jpg`} />
                <div className="content-inner padin-20">
                  <p className="para">Camille Gunesekera, with over 30 years in the travel industry, is a vital business partner at Viluxur Holidays. Leading the Sri Lankan operations, he complements Shafraz's strategic vision with his unorthodox yet highly effective leadership style. Renowned for his innovative problem-solving skills, Camille ensures company goals are met through unique and reliable methods, driving success and excellence for Viluxur Holidays in Sri Lanka</p>
                  <h3 className="name"><span className="font-bembo">Camille Gunesekera</span></h3>

                </div>
              </div>
              <div className="grid-item item3">
                <img className=" width-100" src={process.env.PUBLIC_URL + `/images/meet-team/Nisha.jpg`} />
                <div className="content-inner padin-20">
                  <p className="para">Dr. Abu Bakar’s academic, research and consultancy expertise are in the areas of MICE, Destination marketing, Women in Tourism, Sustainable Tourism, Halal Tourism and Tourism education. </p>
                  <h3 className="name "><span className="font-bembo">Dr. Nisha Abu Bakar </span></h3>

                </div>

              </div>
              <div className="grid-item item4">

                <img className="width-100 " src={process.env.PUBLIC_URL + `/images/meet-team/ELLICE.jpg`} />
                <div className="content-inner padin-20">
                  <p className="para">The Chief Operating Officer of Viluxur Group has over a decade of experience in the travel and tourism sectors of the Maldives. Ellice currently heads the sales divisions of Viluxur and OV. </p>
                  <h3 className="name"><span className="font-bembo">Ellice Bugayong</span></h3>

                </div>

              </div>
              <div className="grid-item item5">

                <img className=" width-100" src={process.env.PUBLIC_URL + `/images/meet-team/HYDEE.jpg`} />
                <div className="content-inner padin-20">
                  <p className="para">Haydee is dedicated to the Americas market, taking personal care of all inquiries of the travel trade and with her extensive experience and knowledge of the Maldives after working and living in the destination for over a decade. </p>
                  <h3 className="name "><span className="font-bembo">Haydee Cruz </span></h3>

                </div>

              </div>
              <div className="grid-item item6">

                <img className="width-100" src={process.env.PUBLIC_URL + `/images/meet-team/ANNA.jpg`} />
                <div className="content-inner padin-20">
                  <p className="para">Anastasia is our voice in Russia. With a majority of our client base in the Russian market, Anastasia ensures our agents have a comprehensive guide to the Maldives by recommending our best selling resorts in the islands </p>
                  <h3 className="name"><span className="font-bembo">Anastasia Grogul</span></h3>

                </div>

              </div>
              <div className="grid-item item7">

                <img className="width-100 " src={process.env.PUBLIC_URL + `/images/chamindra-new.jpg`} />
                <div className="content-inner padin-20">
                  <p className="para">A real numbers man, the organization’s Director of Finance and Strategic Planning, Chamindra holds in his palms the future of Viluxur Group.  </p>
                  <h3 className="name"><span className="font-bembo">Chamindra Gamage</span></h3>

                </div>



              </div>
              <div className="grid-item item8">
                <img className="width-100" src={process.env.PUBLIC_URL + `/images/shaheed_360x450.jpg`} />
                <div className="content-inner padin-20">
                  <p className="para">The Head of Infrastructure and Networking works tirelessly in the back to ensure all operations run smooth. From websites, to phone services to online chat programs, Shahid is the reason our services are never down, no matter which parts of the world we are operating from. </p>
                  <h3 className="name"><span className="font-bembo">Ibrahim Shahid</span></h3>

                </div>
              </div>
              <div className="grid-item item9">
                <img className=" width-100" src={process.env.PUBLIC_URL + `/images/HR_360_450.jpg`} />
                <div className="content-inner padin-20">
                  <p className="para">The HR Manager to the entire conglomerate. Tharushni spends her days attending to all matter related to the staff. Here is a leader who has to tread vigilantly and ensure all are treated fairly and justly in whichever part of the world they work from.</p>
                  <h3 className="name "><span className="font-bembo">Tharushni Weerawarnasuriya </span></h3>

                </div>
              </div>
            </div>
          </div>

          <section className="container-fluid mt-8">
            <div className="row">
              <div className="col-12">
                <motion.img className="width-100" src={process.env.PUBLIC_URL + `/images/wearelocalglobal-new.jpg`} />
              </div>
            </div>
          </section>

          <section className="container">
            <div className="row margin-top-and-botm ">
              <div className="col-12 ">
                <div className=" ">
                  <div>
                    <h2 className="title-2 text-center">Our strongest aspect is our multicultural team. We are a part of a large global family. By global, we mean the individuals who are part of the Viluxur team.</h2>

                  </div>
                </div>
              </div>

            </div>

            <div className="row ">
              <div className="col-3 pdn-50-left-r">
                <img className="width-100" src={process.env.PUBLIC_URL + `/images/abdulla_360x450.jpg`} />
                <div className="content-inner padin-20">
                  <p className="para para-m-botom">Multi Media Artist </p>
                  <h3 className="name"><span className="font-bembo">Abdulla Riyal</span></h3>

                </div>
              </div>
              <div className="col-3 pdn-50-left-r">
                <img className=" width-100" src={process.env.PUBLIC_URL + `/images/Fallon_New.jpg`} />
                <div className="content-inner padin-20">
                  <p className="para para-m-botom">Senior Sales Executive</p>
                  <h3 className="name"><span className="font-bembo">Fallon Mannampulle</span></h3>

                </div>
              </div>
              <div className="col-3 pdn-50-left-r">
                <img className="width-100 " src={process.env.PUBLIC_URL + `/images/sandali_360x450.jpg`} />
                <div className="content-inner padin-20">
                  <p className="para para-m-botom">Destination Specialist </p>
                  <h3 className="name"><span className="font-bembo">Sandini Kavisha</span></h3>

                </div>
              </div>

              <div className="col-3 pdn-50-left-r">
                <img className=" width-100" src={process.env.PUBLIC_URL + `/images/jessica_360x450.jpg`} />
                <div className="content-inner padin-20">

                  <p className="para para-m-botom">Destination Specialist</p>
                  <h3 className="name"><span className="font-bembo">Jessica Villaruel Gerard</span></h3>

                </div>
              </div>
            </div>

            <div className="row mt-10">
              <div className="col-3 pdn-50-left-r">
                <img className="width-100" src={process.env.PUBLIC_URL + `/images/maria_putin.jpg`} />
                <div className="content-inner padin-20">
                  <p className="para para-m-botom">Reservations Manager </p>
                  <h3 className="name"><span className="font-bembo">Maria Purin</span></h3>

                </div>
              </div>
              <div className="col-3 pdn-50-left-r">
                <img className="width-100 " src={process.env.PUBLIC_URL + `/images/ava.jpg`} />
                <div className="content-inner padin-20">
                  <p className="para para-m-botom">Destination Specialist </p>
                  <h3 className="name"><span className="font-bembo">Degamo, Leny Anne C</span></h3>

                </div>
              </div>
              <div className="col-3 pdn-50-left-r">
                <img className=" width-100" src={process.env.PUBLIC_URL + `/images/sachith.jpg`} />
                <div className="content-inner padin-20">
                  <p className="para para-m-botom">Accountant</p>
                  <h3 className="name"><span className="font-bembo">Sachith Induwara</span></h3>

                </div>
              </div>
              <div className="col-3 pdn-50-left-r">
                <img className=" width-100" src={process.env.PUBLIC_URL + `/images/emma_360x450.jpg`} />
                <div className="content-inner padin-20">

                  <p className="para para-m-botom">Business Development Executive</p>
                  <h3 className="name"><span className="font-bembo">Emma Bell</span></h3>

                </div>
              </div>
            </div>
            <div className="row mt-10">
              <div className="col-3 pdn-50-left-r">
                <img className="width-100" src={process.env.PUBLIC_URL + `/images/angela.jpg`} />
                <div className="content-inner padin-20">
                  <p className="para para-m-botom">Reservations Manager </p>
                  <h3 className="name"><span className="font-bembo">Angela</span></h3>

                </div>
              </div>
              <div className="col-3 pdn-50-left-r">
                <img className="width-100 " src={process.env.PUBLIC_URL + `/images/meet-team/bianca.jpg `} />
                <div className="content-inner padin-20">
                  <p className="para para-m-botom">Sales Manager </p>
                  <h3 className="name"><span className="font-bembo">Bianca</span></h3>

                </div>
              </div>
              <div className="col-3 pdn-50-left-r">
                <img className=" width-100" src={process.env.PUBLIC_URL + `/images/marjorie.jpg`} />
                <div className="content-inner padin-20">
                  <p className="para para-m-botom">Accountant</p>
                  <h3 className="name"><span className="font-bembo">Marjorie </span></h3>

                </div>
              </div>
              <div className="col-3 pdn-50-left-r">
                <img className=" width-100" src={process.env.PUBLIC_URL + `/images/jingyi.jpg`} />
                <div className="content-inner padin-20">

                  <p className="para para-m-botom">Business Development Executive</p>
                  <h3 className="name"><span className="font-bembo"> Jingyi</span></h3>

                </div>
              </div>
            </div>

          </section>

          <section className="container">
            <div className="row margin-top-and-botm">
              <div className="col-12 ">
                <div className=" ">
                  <div>
                    <h2 className="title-2 text-center">Once a Viluxurian, Always a Viluxurian </h2>
                    <p className="para text-center">We believe in close connections and are always happy when our team members move to new aspirations.
                    </p>

                    <p className="para text-center">Our former team memebers, too feel a ‘soul connection’ to their once place of employment and <br /> are always ready to share a good work on our behalf.
                    </p>
                  </div>
                </div>
              </div>

            </div>
            <div className="row">
              <Carousel>
                <Carousel.Item>
                  <img
                    className="d-block img-carsol"
                    src={process.env.PUBLIC_URL + `/images/Leswith.jpg`}
                    alt="First slide"
                  />
                  <Carousel.Caption>
                    <p className="para">I learnt and had my biggest impactful career growth with Viluxur Holidays. I am always honored to have been part of that great adventure. </p>
                    <h3 className="name"><span className="font-bembo">Liswith</span></h3>
                  </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    className="d-block img-carsol"
                    src={process.env.PUBLIC_URL + `/images/Jessy.jpg`}
                    alt="Second slide"
                  />
                  <Carousel.Caption>
                    <p className="para">Viluxur was not just an organiztion but a family. Our work together in a whole created some of the best trips for our customers, your clients. I think that is what makes the brand unique. They personalize each trip and that is something many claim but few actually achieve.  </p>
                    <h3 className="name"><span className="font-bembo">Jessie Jingwen</span></h3>
                  </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    className="d-block img-carsol"
                    src={process.env.PUBLIC_URL + `/images/sita.jpg`}
                    alt="Second slide"
                  />
                  <Carousel.Caption>
                    <p className="para">Viluxur Holidays and the Maldives are inseparable and essential elements in my life that not only enriched my experiences, especially during the early start of my career in the tourism industry but also opened my horizons as an individual. </p>
                    <h3 className="name"><span className="font-bembo">Sita</span></h3>
                  </Carousel.Caption>
                </Carousel.Item>
              </Carousel>
            </div>
          </section>
        </div>
      }


















      {isTabletOrMobile &&
        <div className="page-space-top-mobile team-mobile">
          <motion.div
            initial={{ opacity: 0, y: 180 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{
              ease: "easeInOut",
              duration: 1,
              delay: 0.6,
            }}
            className=""
          >
            <section className="container-fluid min-zindex">
              <div className="row">
                <div className="col-12">
                  <Carousel className="local-carasol min-zindex" fade>
                    <Carousel.Item>
                      <img
                        className="d-block w-100"
                        src={process.env.PUBLIC_URL + `/images/loca-global-banner.jpg`}
                        alt="First slide"
                      />
                      <Carousel.Caption>
                        <div className="we-got-you-2">
                          <div>


                            <h1 className="title-2 text-uppercase">We Are <br />Local Global </h1>
                            <p className="para ">We work closely with our destination partners to ensure the establishment of luxurious gateways and support services connecting new markets and destinations. </p>


                          </div>
                        </div>
                      </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                      <img
                        className="d-block w-100"
                        src={process.env.PUBLIC_URL + `/images/chaina_staff-new.jpg`}
                        alt="Second slide"
                      />

                      <Carousel.Caption>
                        <div className="we-got-you-2">
                          <div>


                            <h1 className="title-2 text-uppercase">We Are <br />Local Global </h1>
                            <p className="para">We work closely with our destination partners to ensure the establishment of luxurious gateways and support services connecting new markets and destinations. </p>


                          </div>
                        </div>
                      </Carousel.Caption>
                    </Carousel.Item>

                  </Carousel>
                </div>
              </div>
            </section>

            <div className="we-got-you">
              <div>

                <h1 className="title-2 text-uppercase">We Are <br />Local Global </h1>
                <p className="para ">We work closely with our destination partners to ensure the establishment of luxurious gateways and support services connecting new markets and destinations. </p>
              </div>
            </div>



            <section className="container mt-8">
              <div className="row ">
                <div className="col-12 ">
                  <div className="padding-r-l-30 ">
                    <div>
                      <h2 className="title-2 text-center">Meet The Team That Makes It All Possible  </h2>
                      <p className="para text-center">Our team of travel experts are located across the globe with unique experiences and are at the core of our operation as an industry leader. As a company that caters to the whole world, we understand the importance of good communication and having a diverse worldview.
                      </p>

                      <p className="para text-center">This is why our team is multinational and well-exposed to the journeys we guide our customers through. <br /> It is the distinct charm of the Viluxur experience.
                      </p>
                    </div>
                  </div>
                </div>

              </div>
              <div className="row">
                <Carousel className="meet-the-team-carasol">
                  <Carousel.Item>
                    <img
                      className="d-block meet-team-carasol-img"
                      src={process.env.PUBLIC_URL + `/images/meet-team/SHAFFI.jpg`}
                      alt="First slide"
                    />
                    <Carousel.Caption>
                      <p className="para">As the Managing Director of Viluxur Holidays, Shafraz Fazley is the strategic mind driving the company forward. He envisions and meticulously designs the business's structure and global positioning, aiming to make clients proud partners. While constantly seeking innovative solutions, Shafraz favors steady, evolutionary changes over rapid, exponential shifts. His leadership is focused on creating a lasting impact in the luxury travel industry, guiding Viluxur Holidays towards a prestigious global presence with a commitment to excellence and sustainable growth.</p>
                      <h3 className="name"><span className="font-bembo">Shafraz Fazley</span></h3>
                    </Carousel.Caption>
                  </Carousel.Item>

                  <Carousel.Item>
                    <img
                      className="d-block meet-team-carasol-img"
                      src={process.env.PUBLIC_URL + `/images/meet-team/Cammy.jpg`}
                      alt="First slide"
                    />
                    <Carousel.Caption>
                      <p className="para">Camille Gunesekera, with over 30 years in the travel industry, is a vital business partner at Viluxur Holidays. Leading the Sri Lankan operations, he complements Shafraz's strategic vision with his unorthodox yet highly effective leadership style. Renowned for his innovative problem-solving skills, Camille ensures company goals are met through unique and reliable methods, driving success and excellence for Viluxur Holidays in Sri Lanka</p>
                      <h3 className="name"><span className="font-bembo">Camille Gunesekera</span></h3>
                    </Carousel.Caption>
                  </Carousel.Item>


                  <Carousel.Item>
                    <img
                      className="d-block meet-team-carasol-img"
                      src={process.env.PUBLIC_URL + `/images/meet-team/Nisha.jpg`}
                      alt="Second slide"
                    />
                    <Carousel.Caption>
                      <p className="para">Dr. Abu Bakar’s academic, research and consultancy expertise are in the areas of MICE, Destination marketing, Women in Tourism, Sustainable Tourism, Halal Tourism and Tourism education. </p>
                      <h3 className="name "><span className="font-bembo">Dr. Nisha Abu Bakar </span></h3>
                    </Carousel.Caption>
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      className="d-block meet-team-carasol-img"
                      src={process.env.PUBLIC_URL + `/images/meet-team/ELLICE.jpg`}

                      alt="Second slide"
                    />
                    <Carousel.Caption>
                      <p className="para">The Chief Operating Officer of Viluxur Group has over a decade of experience in the travel and tourism sectors of the Maldives. Ellice currently heads the sales divisions of Viluxur and OV. </p>
                      <h3 className="name"><span className="font-bembo">Ellice Bugayong</span></h3>

                    </Carousel.Caption>
                  </Carousel.Item>

                  <Carousel.Item>
                    <img
                      className="d-block meet-team-carasol-img"
                      src={process.env.PUBLIC_URL + `/images/meet-team/HYDEE.jpg`}
                      alt="Second slide"
                    />
                    <Carousel.Caption>
                      <p className="para">Haydee is dedicated to the Americas market, taking personal care of all inquiries of the travel trade and with her extensive experience and knowledge of the Maldives after working and living in the destination for over a decade. </p>
                      <h3 className="name "><span className="font-bembo">Haydee Cruz </span></h3>

                    </Carousel.Caption>
                  </Carousel.Item>

                  <Carousel.Item>
                    <img
                      className="d-block meet-team-carasol-img"
                      src={process.env.PUBLIC_URL + `/images/meet-team/ANNA.jpg`}

                      alt="Second slide"
                    />
                    <Carousel.Caption>
                      <p className="para">Anastasia is our voice in Russia. With a majority of our client base in the Russian market, Anastasia ensures our agents have a comprehensive guide to the Maldives by recommending our best selling resorts in the islands </p>
                      <h3 className="name"><span className="font-bembo">Anastasia Grogul</span></h3>

                    </Carousel.Caption>
                  </Carousel.Item>

                  <Carousel.Item>
                    <img
                      className="d-block meet-team-carasol-img"
                      src={process.env.PUBLIC_URL + `/images/chamindra-new.jpg`}
                      alt="Second slide"
                    />
                    <Carousel.Caption>
                      <p className="para">A real numbers man, the organization’s Director of Finance and Strategic Planning, Chamindra holds in his palms the future of Viluxur Group.  </p>
                      <h3 className="name"><span className="font-bembo">Chamindra Gamage</span></h3>

                    </Carousel.Caption>
                  </Carousel.Item>

                  <Carousel.Item>
                    <img
                      className="d-block meet-team-carasol-img"
                      src={process.env.PUBLIC_URL + `/images/shaheed_360x450.jpg`}
                      alt="Second slide"
                    />
                    <Carousel.Caption>

                      <p className="para">The Head of Infrastructure and Networking works tirelessly in the back to ensure all operations run smooth. From websites, to phone services to online chat programs, Shahid is the reason our services are never down, no matter which parts of the world we are operating from. </p>
                      <h3 className="name"><span className="font-bembo">Ibrahim Shahid</span></h3>
                    </Carousel.Caption>
                  </Carousel.Item>


                  <Carousel.Item>
                    <img
                      className="d-block meet-team-carasol-img"
                      src={process.env.PUBLIC_URL + `/images/HR_360_450.jpg`}
                      alt="Second slide"
                    />
                    <Carousel.Caption>

                      <p className="para">The HR Manager to the entire conglomerate. Tharushni spends her days attending to all matter related to the staff. Here is a leader who has to tread vigilantly and ensure all are treated fairly and justly in whichever part of the world they work from.</p>
                      <h3 className="name "><span className="font-bembo">Tharushni Weerawarnasuriya </span></h3>
                    </Carousel.Caption>
                  </Carousel.Item>
                </Carousel>
              </div>
            </section>

            <section className="container-fluid viluxur-top-image-margin">
              <div className="row">
                <div className="col-12">
                  <motion.img className="width-100" src={process.env.PUBLIC_URL + `/images/wearelocalglobal-new.jpg`} />
                </div>
              </div>
            </section>


            <section className="container mt-8">
              <div>
                <div className="row padding-r-l-30">
                  <div className="col-12 ">
                    <div className=" ">
                      <div>
                        <h2 className="title-2 text-center">Our strongest aspect is our multicultural team. We are a part of a large global family.</h2>

                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </section>


            <section className="container mt-8">

              <div className="row">
                <Carousel className="meet-the-team-carasol">
                  <Carousel.Item>
                    <img
                      className="d-block meet-team-carasol-img"
                      src={process.env.PUBLIC_URL + `/images/abdulla_360x450.jpg`}
                      alt="First slide"
                    />
                    <Carousel.Caption>
                      <p className="para para-m-botom">Multi Media Artist </p>
                      <h3 className="name"><span className="font-bembo">Abdulla Riyal</span></h3>
                    </Carousel.Caption>
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      className="d-block meet-team-carasol-img"
                      src={process.env.PUBLIC_URL + `/images/sandali_360x450.jpg`}
                      alt="Second slide"
                    />
                    <Carousel.Caption>
                      <p className="para para-m-botom">Destination Specialist </p>
                      <h3 className="name"><span className="font-bembo">Sandini Kavisha</span></h3>
                    </Carousel.Caption>
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      className="d-block meet-team-carasol-img"
                      src={process.env.PUBLIC_URL + `/images/jessica_360x450.jpg`}
                      alt="Second slide"
                    />
                    <Carousel.Caption>
                      <p className="para para-m-botom">Destination Specialist</p>
                      <h3 className="name"><span className="font-bembo">Jessica Villaruel Gerard</span></h3>

                    </Carousel.Caption>
                  </Carousel.Item>

                  <Carousel.Item>
                    <img
                      className="d-block meet-team-carasol-img"
                      src={process.env.PUBLIC_URL + `/images/Fallon_New.jpg`}
                      alt="Second slide"
                    />
                    <Carousel.Caption>
                      <p className="para para-m-botom">Senior Sales Executive</p>
                      <h3 className="name"><span className="font-bembo">Fallon Mannampulle</span></h3>

                    </Carousel.Caption>
                  </Carousel.Item>

                  <Carousel.Item>
                    <img
                      className="d-block meet-team-carasol-img"
                      src={process.env.PUBLIC_URL + `/images/maria_putin.jpg`}
                      alt="Second slide"
                    />
                    <Carousel.Caption>
                      <p className="para para-m-botom">Reservations Manager </p>
                      <h3 className="name"><span className="font-bembo">Maria Sokol</span></h3>

                    </Carousel.Caption>
                  </Carousel.Item>

                  <Carousel.Item>
                    <img
                      className="d-block meet-team-carasol-img"
                      src={process.env.PUBLIC_URL + `/images/ava.jpg`}
                      alt="Second slide"
                    />
                    <Carousel.Caption>
                      <p className="para para-m-botom">Destination Specialist </p>
                      <h3 className="name"><span className="font-bembo">Degamo, Leny Anne C</span></h3>

                    </Carousel.Caption>
                  </Carousel.Item>

                  <Carousel.Item>
                    <img
                      className="d-block meet-team-carasol-img"
                      src={process.env.PUBLIC_URL + `/images/sachith.jpg`}
                      alt="Second slide"
                    />
                    <Carousel.Caption>

                      <p className="para para-m-botom">Accountant</p>
                      <h3 className="name"><span className="font-bembo">Sachith Induwara</span></h3>
                    </Carousel.Caption>
                  </Carousel.Item>


                  <Carousel.Item>
                    <img
                      className="d-block meet-team-carasol-img"
                      src={process.env.PUBLIC_URL + `/images/emma_360x450.jpg`}
                      alt="Second slide"
                    />
                    <Carousel.Caption>
                      <p className="para para-m-botom">Business Development Executive</p>
                      <h3 className="name"><span className="font-bembo">Emma Bell</span></h3>
                    </Carousel.Caption>
                  </Carousel.Item>

                  <Carousel.Item>
                    <img
                      className="d-block meet-team-carasol-img"
                      src={process.env.PUBLIC_URL + `/images/meet-team/Angela-300.jpg`}
                      alt="Second slide"
                    />
                    <Carousel.Caption>
                      <p className="para para-m-botom">Reservations Manager</p>
                      <h3 className="name"><span className="font-bembo">Angela</span></h3>
                    </Carousel.Caption>
                  </Carousel.Item>

                  <Carousel.Item>
                    <img
                      className="d-block meet-team-carasol-img"
                      src={process.env.PUBLIC_URL + `/images/meet-team/bianca.jpg`}
                      alt="Second slide"
                    />
                    <Carousel.Caption>
                      <p className="para para-m-botom">Sales Manager </p>
                      <h3 className="name"><span className="font-bembo">Bianca</span></h3>
                    </Carousel.Caption>
                  </Carousel.Item>

                  <Carousel.Item>
                    <img
                      className="d-block meet-team-carasol-img"
                      src={process.env.PUBLIC_URL + `/images/meet-team/Marjorie-Jordas-300.jpg`}
                      alt="Second slide"
                    />
                    <Carousel.Caption>
                      <p className="para para-m-botom">Accountant</p>
                      <h3 className="name"><span className="font-bembo">Marjorie</span></h3>
                    </Carousel.Caption>
                  </Carousel.Item>

                  <Carousel.Item>
                    <img
                      className="d-block meet-team-carasol-img"
                      src={process.env.PUBLIC_URL + `/images/meet-team/Jingyi-300.jpg`}
                      alt="Second slide"
                    />
                    <Carousel.Caption>
                      <p className="para para-m-botom">Business Development Executive</p>
                      <h3 className="name"><span className="font-bembo">Jingyi</span></h3>
                    </Carousel.Caption>
                  </Carousel.Item>
                </Carousel>

              </div>
            </section>



            <section className="container viluxur-top-image-margin-2">
              <div className="row ">
                <div className="col-12 ">
                  <div className="padding-r-l-30 ">
                    <div>
                      <h2 className="title-2 text-center">Once a Viluxurian, Always a Viluxurian </h2>
                      <p className="para text-center">We believe in close connections and are always happy when our team members move to new aspirations.
                      </p>

                      <p className="para text-center">Our former team memebers, too feel a ‘soul connection’ to their once place of employment and <br /> are always ready to share a good work on our behalf.
                      </p>
                    </div>
                  </div>
                </div>

              </div>
              <div className="row">
                <Carousel fade>
                  <Carousel.Item>
                    <img
                      className="d-block img-carsol"
                      src={process.env.PUBLIC_URL + `/images/Leswith.jpg`}
                      alt="First slide"
                    />
                    <Carousel.Caption>
                      <p className="para">I learnt and had my biggest impactful career growth with Viluxur Holidays. I am always honored to have been part of that great adventure. </p>
                      <h3 className="name"><span className="font-bembo">Liswith</span></h3>
                    </Carousel.Caption>
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      className="d-block img-carsol"
                      src={process.env.PUBLIC_URL + `/images/Jessy.jpg`}
                      alt="Second slide"
                    />
                    <Carousel.Caption>
                      <p className="para">Viluxur was not just an organiztion but a family. Our work together in a whole created some of the best trips for our customers, your clients. I think that is what makes the brand unique. They personalize each trip and that is something many claim but few actually achieve.  </p>
                      <h3 className="name"><span className="font-bembo">Jessie Jingwen</span></h3>
                    </Carousel.Caption>
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      className="d-block img-carsol"
                      src={process.env.PUBLIC_URL + `/images/sita.jpg`}
                      alt="Second slide"
                    />
                    <Carousel.Caption>
                      <p className="para">Viluxur Holidays and the Maldives are inseparable and essential elements in my life that not only enriched my experiences, especially during the early start of my career in the tourism industry but also opened my horizons as an individual. </p>
                      <h3 className="name"><span className="font-bembo">Sita</span></h3>
                    </Carousel.Caption>
                  </Carousel.Item>
                </Carousel>
              </div>
            </section>


            <section className="mt-8">

            </section>
          </motion.div>

        </div>



      }



      <Footer />
    </div>
  );
}

const BannerRowTop = ({ title }) => {
  return (
    <div className={"banner-row"}>
      <div className="">
        <AnimatedLetters title={"We're"} />
        <AnimatedLetters2 className="gray-font" title={'Local'} />
        <AnimatedLetters title={'Global'} />
      </div>
    </div>
  );
};

const BannerRowBottom = ({ title }) => {
  return (
    <div className={"banner-row center ml-5"}>
      <motion.div
        initial={{ scale: 0 }}
        animate={{ scale: 1 }}
        transition={{ ease: [0.6, 0.01, -0.05, 0.95], duration: 1, delay: 1 }} >
      </motion.div>
      <AnimatedLetters title={title} />
    </div>
  );
};

const BannerRowCenter = ({ title, playMarquee }) => {
  return (
    <div className={`banner-row marquee  ${playMarquee && "animate"}`}>
      <div className="marquee__inner luxury-m-titile">
        <AnimatedLetters title={title} />
      </div>
    </div>
  );
};

const AnimatedLetters = ({ title, disabled }) => (
  <motion.span
    className="row-title"
    variants={disabled ? null : banner}
    initial="initial"
    animate="animate">
    {[...title].map((letter) => (
      <motion.span variants={disabled ? null : letterAni} className="row-letter">{letter}</motion.span>
    ))}
  </motion.span>
);


const AnimatedLetters2 = ({ title, disabled }) => (
  <motion.span
    className="row-title-gray row-title"
    variants={disabled ? null : banner}
    initial="initial"
    animate="animate">
    {[...title].map((letter) => (
      <motion.span variants={disabled ? null : letterAni} className="row-letter">{letter}</motion.span>
    ))}
  </motion.span>
);


const BannerRowTopNew = ({ title }) => {
  return (
    <div className={"banner-row"}>
      <div className="">
        <AnimatedLetters title={title} />
      </div>

    </div>
  );
};

const itemData = [
  {
    img: 'https://www.viluxurholidays.com/images/new-viluxur/team/PG_8.jpg',

  },
  {
    img: 'https://www.viluxurholidays.com/images/new-viluxur/team/PG_11T.jpg',

  },
  {
    img: 'https://www.viluxurholidays.com/images/new-viluxur/team/PG_9.jpg',

  },
  {
    img: 'https://www.viluxurholidays.com/images/new-viluxur/team/PG_12T.jpg',

  },

  {
    img: 'https://www.viluxurholidays.com/images/new-viluxur/team/PG_13T.jpg',

  },

  {
    img: 'https://www.viluxurholidays.com/images/new-viluxur/team/PG_10.jpg',

  },
  {
    img: 'https://www.viluxurholidays.com/images/new-viluxur/team/PG_14T.jpg',

  },
  {
    img: 'https://www.viluxurholidays.com/images/new-viluxur/team/PG_5.jpg',

  },
  {
    img: 'https://www.viluxurholidays.com/images/new-viluxur/team/PG_15T.jpg',

  },

  {
    img: 'https://www.viluxurholidays.com/images/new-viluxur/team/PG_16T.jpg',

  },
  {
    img: 'https://www.viluxurholidays.com/images/new-viluxur/team/PG_3.jpg',

  },
  {
    img: 'https://www.viluxurholidays.com/images/new-viluxur/team/PG_11.jpg',

  },



];


export default Team;