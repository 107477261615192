import React from "react";
import { motion } from "framer-motion";
import { NavLink } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive'

const Header = () => {

    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    })
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    const whatsappMessage = "Viluxur Holidays"
    return (
        <div>
            {isDesktopOrLaptop &&
                <motion.div
                    initial={{ opacity: 0, y: -180 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{
                        ease: "easeInOut",
                        duration: 1,
                        delay: 0.6,
                    }}
                    className="header"
                >
                    <motion.div className="header-inner">
                        <div className="logo">
                            <NavLink to={`${process.env.PUBLIC_URL}/`} key={`${process.env.PUBLIC_URL}/`}  >
                                <span className="logo"> Viluxur Holidays </span>
                            </NavLink> </div>
                        <nav className="nav">
                            <li>
                                <NavLink to={`${process.env.PUBLIC_URL}/5s-of-luxury`} key={`${process.env.PUBLIC_URL}/5s-of-luxury`}  >
                                    <div className="flip-animate" ><span data-hover="5 Ss of Luxury">5 Ss of Luxury</span> </div>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to={`${process.env.PUBLIC_URL}/team`} key={`${process.env.PUBLIC_URL}/team`}  >
                                    <div className="flip-animate" ><span data-hover="Team">Team</span> </div>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to={`${process.env.PUBLIC_URL}/rebuild-now`} key={`${process.env.PUBLIC_URL}/rebuild-now`}  >
                                    <div className="flip-animate" ><span data-hover="Rebuild NOW">Rebuild NOW</span> </div>
                                </NavLink>
                            </li>
                        </nav>
                        <div className="contact">
                            {/* <a className="hover-event whatsapp-main-wrapper" href={`https://api.whatsapp.com/send?phone=9607985454&text=${encodeURIComponent(whatsappMessage)}`} >
                                <span><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7 .9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z" /></svg></span> +960 798 54 54
                            </a> */}
                            <NavLink to={`${process.env.PUBLIC_URL}/contact-us`} key={`${process.env.PUBLIC_URL}/contact-us`}  >
                                <div className="flip-animate hover-event"><span data-hover="Let's work together">Let's work together</span></div>
                            </NavLink>
                        </div>
                    </motion.div>
                </motion.div>
            }

            {isTabletOrMobile &&
                <motion.div
                    initial={{ opacity: 0, y: -180 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{
                        ease: "easeInOut",
                        duration: 1,
                        delay: 0.6,
                    }}
                    className=""
                >
                    <motion.div>
                        <div className="container">
                            <div className="row">
                                <div className="col-6 header-logo">
                                    <NavLink to={`${process.env.PUBLIC_URL}/`} key={`${process.env.PUBLIC_URL}/`}  >
                                        <img src={process.env.PUBLIC_URL + `/images/Viluxur_Holidays_logo_new_gold.svg`} />
                                    </NavLink>
                                </div>
                                <div className="col-6 flex">
                                    {/* <div className="header-call-to-action">
                                        <a className="" href={`https://api.whatsapp.com/send?phone=9607985454&text=${encodeURIComponent(whatsappMessage)}`} ><img src={process.env.PUBLIC_URL + `/images/call-svgrepo-com-01.svg`} /></a>
                                    </div> */}
                                    <nav role="navigation">
                                        <div id="menuToggle">
                                            <input type="checkbox" />
                                            <span></span>
                                            <span></span>
                                            <span></span>
                                            <ul id="menu">
                                                <li className="header-logo mb-5">
                                                    <NavLink to={`${process.env.PUBLIC_URL}/`} key={`${process.env.PUBLIC_URL}/`}  >
                                                        <img src={process.env.PUBLIC_URL + `/images/Viluxur_Holidays_logo_new_gold.svg`} />
                                                    </NavLink>
                                                </li>
                                                <li>
                                                    <NavLink to={`${process.env.PUBLIC_URL}/5s-of-luxury`} key={`${process.env.PUBLIC_URL}/5s-of-luxury`}  >
                                                        <div className="arrow-righ">We are viluxur </div>
                                                    </NavLink>
                                                </li>
                                                <li>
                                                    <NavLink to={`${process.env.PUBLIC_URL}/team`} key={`${process.env.PUBLIC_URL}/team`}  >
                                                        <div className="arrow-righ">We are local global</div>
                                                    </NavLink>
                                                </li>
                                                <li>
                                                    <NavLink to={`${process.env.PUBLIC_URL}/rebuild-now`} key={`${process.env.PUBLIC_URL}/rebuild-now`}  >
                                                        <div className="arrow-righ">We are the future</div>
                                                    </NavLink>
                                                </li>
                                                <li>
                                                    <NavLink to={`${process.env.PUBLIC_URL}/vh-vip`} key={`${process.env.PUBLIC_URL}/vh-vip`}  >
                                                        <div className="arrow-righ">Viluxur Vip
                                                        </div>
                                                    </NavLink>
                                                </li>
                                                <li>
                                                    <NavLink to={`${process.env.PUBLIC_URL}/vh-experience`} key={`${process.env.PUBLIC_URL}/vh-experience`}  >
                                                        <div className="arrow-righ">Viluxur Experience</div>
                                                    </NavLink>
                                                </li>
                                                <li>
                                                    <NavLink to={`${process.env.PUBLIC_URL}/contact-us`} key={`${process.env.PUBLIC_URL}/contact-us`}  >
                                                        <div className="arrow-righ">Let's work together</div>
                                                    </NavLink>
                                                </li>
                                                <li className="bottom-menu bottom-menu-item">
                                                </li>
                                                <li className="email font-small m-header-top">
                                                    <a href="mailto:info@viluxurholidays.com">info@viluxurholidays.com</a>
                                                </li>
                                                {/* <li className="phone font-small">
                                                    <a className="" href={`https://api.whatsapp.com/send?phone=9607985454&text=${encodeURIComponent(whatsappMessage)}`} >
                                                        +960 798 54 54
                                                    </a>
                                                </li> */}
                                            </ul>
                                        </div>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </motion.div>
                </motion.div>
            }
        </div>
    );
};

export default Header;
