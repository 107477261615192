import { useEffect, useState, useRef } from "react";
import { motion, AnimatePresence, AnimateSharedLayout } from "framer-motion";
import "../sass/main.scss";
import ReactPlayer from 'react-player'

// Components
import Banner from "../components/Banner";
import Loader from "../components/Loader";
import Middle from "../components/Middle";
import Footer from "../components/Footer";
import Neo from "../components/Neo";
import Header from "../components/Header";

function Home() {
	const [loading, setLoading] = useState(true);
	const videoEl = useRef(null);

	const attemptPlay = () => {
		videoEl &&
			videoEl.current &&
			videoEl.current.play().catch(error => {
				console.error("Error attempting to play", error);
			});
	};

	useEffect(() => {
		attemptPlay();
	}, []);

	useEffect(() => {
		loading
			? document.querySelector("body").classList.add("loading")
			: document.querySelector("body").classList.remove("loading");
	}, [loading]);

	return (

		<AnimateSharedLayout >
			<AnimatePresence>
				<>
					<Header />
					<Banner />
					<div className="transition-image final">
						<div className="home_video">
							<video
								style={{ margin: "0 auto", width: "100%" }}
								playsInline
								loop
								muted
								controls
								alt="All the devices"
								src="https://www.viluxurholidays.com/media/viluxur_holiday.mp4"
								ref={videoEl}
							/>
						</div>
					</div>
					<Middle />
					<Footer />
				</>
			</AnimatePresence>
		</AnimateSharedLayout>
	);
}

export default Home;
