import React, { useState, useRef, useEffect  } from "react";
import { motion, useViewportScroll, useTransform } from "framer-motion";

import Footer from "../components/Footer";
import Header from "../components/Header";
import { useMediaQuery } from 'react-responsive';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Masonry from '@mui/lab/Masonry';
import { styled } from '@mui/material/styles';

const Label = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(0.5),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0,
}));

const banner = {
    animate: {
      transition: {
        delayChildren: 0.4,
        staggerChildren: 0.1,
      },
    },
  };
  
  const letterAni = {
    initial: { y: 400 },
    animate: {
      y: 0,
      transition: {
        ease: [0.6, 0.01, -0.05, 0.95],
        duration: 1,
      },
    },
  };


  const Ellice = ({ speed }) => {
    const { scrollYProgress } = useViewportScroll();
    const yValue = useTransform(scrollYProgress, [0, 1], [0, 200*speed]);
  
        return(
          <motion.div initial={{opacity:0}} animate={{opacity:1}} transition={{duration:1}} style={{y:yValue}}>
             <img className="img-100" src={process.env.PUBLIC_URL + `/images/smalll.jpg`} />
          </motion.div>
        )
  };
  

function Carreer() {
 

  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 1224px)'
  })
  const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
  const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })


  return (
    <div>
        <Header/>

        {isDesktopOrLaptop && 
          <div>
            <motion.div className="banner  m-xy-s viluxur-m-top-header banner-inner" variants={banner}>
        <BannerRowTop />
        
        </motion.div>
       
        <div className="transition-image mt-final final">
        <motion.img src={process.env.PUBLIC_URL + `/images/careers_banner.jpg`} />
        </div>

        <section className="container  top-botom-m">
            <div className="row">
                <div className="col-12 px-5 ">
                <p className="para text-center">Unfortunately, Viluxur Group is not hiring at this very moment. However, please fill in the form below and drop your resume/CV and our team at Human Resources will contact you should we have an opening . 
               </p>
               <h3 className="title-2 text-center carrer-page-email">
                <a href="mailto:info@viluxurholidays.com">info@viluxurholidays.com</a>
                </h3>
                </div>
            </div>
        </section>


          </div>
          
        }
        


      {isTabletOrMobile && 
          <div>
             <motion.div
initial={{ opacity: 0, y: 180 }}
animate={{ opacity: 1, y: 0 }}
transition={{
  ease: "easeInOut",
  duration: 1,
  delay: 0.6,}}
  className=""
>
            <motion.div className="banner" variants={banner}>
            <BannerRowTopNew title={"Careers"} />
 
          </motion.div>

          <div className="transition-image mt-final final">
                <motion.img src={process.env.PUBLIC_URL + `/images/careers_banner.jpg`} />
            </div>

        <section className="container careers-space">
            <div className="row">
                <div className="col-12 px-5 ">
                <p className="para text-center">Unfortunately, Viluxur Group is not hiring at this very moment. However, please fill in the form below and drop your resume/CV and our team at Human Resources will contact you should we have an opening . 
               </p>
                    
                <h3 className="title-2 text-center carrer-page-email">
                <a href="mailto:info@viluxurholidays.com">info@viluxurholidays.com</a>
                </h3>
               
                </div>
            </div>
        </section>

    
    
      </motion.div>

          </div>
       }
            
        
        
        <Footer/>
    </div>
  );
}

const BannerRowTop = ({ title }) => {
    return (
      <div className={"banner-row"}>
        <div className="">
          <AnimatedLetters title={"Careers"} />
      
        </div>
      </div>
    );
  };
  
  const BannerRowBottom = ({ title }) => {
    return (
      <div className={"banner-row center ml-5"}>
              <motion.div
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{ ease: [0.6, 0.01, -0.05, 0.95], duration: 1, delay: 1 }} >
        </motion.div>
        <AnimatedLetters title={title} />
      </div>
    );
  };
  
  const BannerRowCenter = ({ title, playMarquee }) => {
    return (
      <div className={`banner-row marquee  ${playMarquee && "animate"}`}>
        <div className="marquee__inner luxury-m-titile">
        <AnimatedLetters title={title}  />
        </div>
      </div>
    );
  };

  const AnimatedLetters = ({ title, disabled }) => (
    <motion.span 
    className="row-title"
    variants={disabled ? null : banner}
    initial="initial"
    animate="animate">
      {[...title].map((letter) => (
        <motion.span   variants={disabled ? null : letterAni} className="row-letter">{letter}</motion.span>
      ))}
    </motion.span>
  );


  const AnimatedLetters2 = ({ title, disabled }) => (
    <motion.span 
    className="row-title-gray row-title"
    variants={disabled ? null : banner}
    initial="initial"
    animate="animate">
      {[...title].map((letter) => (
        <motion.span   variants={disabled ? null : letterAni} className="row-letter">{letter}</motion.span>
      ))}
    </motion.span>
  );


  const BannerRowTopNew = ({ title }) => {
    return (
      <div className={"banner-row"}>
        <div className="">
          <AnimatedLetters title={title} />
        </div>
    
      </div>
    );
  };


  


export default Carreer;