import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";

const banner = {
  animate: {
    transition: {
      delayChildren: 0,
      staggerChildren: 0.1,
    },
  },
};

const letterAni = {
  initial: { y: 400 },
  animate: {
    y: 0,
    transition: {
      ease: [0.6, 0.01, -0.05, 0.95],
      duration: 1,
    },
  },
};



const Banner = () => {
  const [playMarquee, setPlayMarquee] = useState(false);

  useEffect(() => {
    setPlayMarquee(true);
    
  }, []);
  return (
    <motion.div className="banner" variants={banner}>
      <BannerRowTop title={"Your"} />
      <BannerRowCenter className="middle-title" title={"One-Stop Travel"} playMarquee={playMarquee} />
      <spna className="line-h-1">
      <BannerRowBottom title={"Concierge "} />
      </spna>
      
    </motion.div>
  );
};

const AnimatedLetters = ({ title, disabled }) => (
  <motion.span 
  className="row-title"
  variants={disabled ? null : banner}
  initial="initial"
  animate="animate">
    {[...title].map((letter) => (
      <motion.span   variants={disabled ? null : letterAni} className="row-letter">{letter}</motion.span>
    ))}
  </motion.span>
);

const BannerRowTop = ({ title }) => {
  return (
    <div className={"banner-row"}>
      <div className="row-col-old">
        <AnimatedLetters title={title} />
      </div>
      <motion.div className="row-col-old"  initial={{ opacity: 0, y: 80 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{
          ease: "easeInOut",
          duration: 1,
          delay: 0.4,
        }}>
        <span className="side-logo-imgs">
        <img src={process.env.PUBLIC_URL + `/images/Viluxur_Holidays_logo_new_gold.svg`} />
        </span>
        <span className="row-message">
              Whether you need an all-in-one platform, a customized approach or a simple spend management tool, you’ll have a solution.
        </span>
      </motion.div>
    </div>
  );
};

const BannerRowBottom = ({ title }) => {
  return (
    <div className={"banner-row center"}>
            <motion.div
        initial={{ scale: 0 }}
        animate={{ scale: 1 }}
        transition={{ ease: [0.6, 0.01, -0.05, 0.95], duration: 1, delay: 1 }}
        className='scroll'>
        <motion.span
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{
            ease: "easeInOut",
            duration: 1,
            delay: 1.8,
          }}>
          scroll
        </motion.span>
        <motion.span
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{
            ease: "easeInOut",
            duration: 1,
            delay: 1.8,
          }}>
          down
        </motion.span>
      </motion.div>
      <AnimatedLetters title={title} />
    </div>
  );
};

const BannerRowCenter = ({ title, playMarquee }) => {
  return (
    <div className={`banner-row marquee  ${playMarquee && "animate"}`}>
      <div className="marquee__inner">
        <AnimatedLetters title={title}  />
        <AnimatedLetters title={title} />
        <AnimatedLetters title={title}  />
        <AnimatedLetters title={title}  />
      </div>
    </div>
  );
};

export default Banner;
